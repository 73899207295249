import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  Box,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMemo, useState } from "react";
import CharmsList from "../../charmsList/components/CharmsList";
import {
  ContentBox,
  SingleColourBox,
  TextBox,
  TextInfoBox,
} from "../styles/index";
import StaticModal from "./StaticModal";

const ProductContent = ({ product, categoryName, charmsList }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedCharms, setSelectedCharms] = useState([]);

  const handleInfoIconClick = () => {
    setShowTooltip(!showTooltip);
  };

  const msgText = useMemo(() => {
    if (!charmsList) {
      return `https://api.whatsapp.com/send?phone=+917021882689&text=Hi Drishti! I'd love to buy ${product.name}. Could you please share more details or connect with me for further assistance? Thanks! Link: https://www.drijewels.com/products/${product._id}`;
    }
    const charmNames = selectedCharms.map((charm) => charm.name).join(", ");
    const lastCharmIndex = charmNames.lastIndexOf(",");
    const charmNamesList = `${charmNames.substring(
      0,
      lastCharmIndex
    )} and ${charmNames.substring(lastCharmIndex + 1)} charms.`;
    return `https://api.whatsapp.com/send?phone=+917021882689&text=Hi Drishti! I'd love to buy a Charms Bracelet with ${charmNamesList} Could you please share more details or connect with me for further assistance? Thanks!`;
  }, [charmsList, selectedCharms]);

  return (
    <ContentBox>
      <TextInfoBox className={"text-info"}>
        {!isMobile && (
          <Link
            underline="none"
            href={
              charmsList ? `/store` : `/categories/${product.categoryNumber}`
            }>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              fontWeight={700}>
              <KeyboardArrowLeftIcon fontSize="large" />
              {`Back to ${categoryName}`}
            </Typography>
          </Link>
        )}
        <TextBox>
          <Typography variant="h3" paddingBottom={"10px"}>
            {product.name}
          </Typography>
          <Typography
            variant="h5"
            fontWeight={600}
            paddingBottom={"10px"}
            sx={{
              span: {
                fontSize: "14px",
              },
            }}>
            <span
              style={{
                fontSize: "18px",
              }}>
              {charmsList && "Bracelet with 5 Charms: "}
            </span>
            ₹ {product.price}.<span>00</span>
            <span
              onClick={handleInfoIconClick}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                marginLeft: "5px",
              }}>
              <InfoIcon fontSize="small" />
            </span>
            {showTooltip && (
              <span
                style={{
                  marginLeft: "5px",
                  fontSize: "12px",
                  color: "#000",
                }}>
                Price of Yellow Gold and Rose Gold may differ as per current
                market prices
              </span>
            )}
          </Typography>
          {charmsList && (
            <Typography
              sx={{
                fontSize: "16px",
                marginBottom: 2,
              }}>
              Add more charms for ₹350 each.
            </Typography>
          )}
          <Typography variant="body1">Available in:</Typography>
          <Box display={"flex"} margin={"2% 0"}>
            {!charmsList && (
              <Box paddingRight={"5%"}>
                <SingleColourBox backgroundColor={"yellow"} />
                <Typography textAlign={"center"}>Yellow</Typography>
              </Box>
            )}
            <Box paddingRight={"5%"} textAlign={"center"}>
              <SingleColourBox backgroundColor={"white"} />
              <Typography textAlign={"center"}>White</Typography>
            </Box>
            {!charmsList && (
              <Box>
                <SingleColourBox backgroundColor={"pink"} />
                <Typography textAlign={"center"}>Rose</Typography>
              </Box>
            )}
          </Box>
          <Button
            variant="contained"
            fullWidth
            href={msgText}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ margin: "15px 0" }}>
            Buy Now
          </Button>
          {!charmsList && (
            <Typography variant="subtitle1" textAlign={"center"}>
              Available in all sizes.
            </Typography>
          )}
        </TextBox>
      </TextInfoBox>
      {charmsList && (
        <CharmsList
          charmsList={charmsList}
          selectedCharms={selectedCharms}
          setSelectedCharms={setSelectedCharms}
        />
      )}
      <StaticModal product={product} />
    </ContentBox>
  );
};

export default ProductContent;
