/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { MuiFileInput } from "mui-file-input";
import {
  CreateContainer,
  Heading,
  ContentBox,
  FileInputBox,
} from "../styles/index";
import SendNotifications from "../../notifications/components/SendNotifications";
import { uploadMultipleToS3, uploadToS3 } from "../../../config/s3Upload";

window.Buffer = window.Buffer || Buffer;

const AdminHeader = ({ categories, collections }) => {
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openCollectionModal, setOpenCollectionModal] = useState(false);
  const [productDetails, setProductDetails] = useState({
    name: "",
    price: "",
    other: "",
    categoryNumber: null,
    collectionNumber: null,
    tags: "",
    image: "",
    sliderImages: "",
    isPublished: true,
    color: "",
    width: "",
    weight: "",
    length: "",
  });
  const [categoryImage, setCategoryImage] = useState(null);
  const [collectionImage, setCollectionImage] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [sliderImages, setSliderImages] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState({
    name: "",
    description: "",
    image: categoryImage,
    categoryNumber: null,
    isPublished: true,
  });
  const [collectionDetails, setCollectionDetails] = useState({
    name: "",
    description: "",
    image: collectionImage,
    collectionNumber: null,
    isPublished: true,
  });
  const [lengthValue, setlengthValue] = useState();
  const [descValue, setDescValue] = useState();
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    entity: "",
    action: "",
  });

  const lengthOptions = [
    {
      label:
        "Customised According To Size (model in the picture is wearing 6 inches chain length)",
      value:
        "Customised According To Size (model in the picture is wearing 6 inches chain length)",
    },
    {
      label:
        "15inches, 16inches, 18inches and 20inches (model in the picture is wearing 16 inches chain length)",
      value:
        "15inches, 16inches, 18inches and 20inches (model in the picture is wearing 16 inches chain length)",
    },
  ];

  const descOptions = [
    {
      label: "*This does not include watch with it",
      value: "*This does not include watch with it",
    },
    {
      label: "Earrings can either be customised in screw, push or flat",
      value: "Earrings can either be customised in screw, push or flat",
    },
  ];

  const createProduct = () => {
    setOpenProductModal(true);
  };

  const createCategory = () => {
    setOpenCategoryModal(true);
  };

  const createCollection = () => {
    setOpenCollectionModal(true);
  };

  const handleProductSubmit = async () => {
    try {
      let updatedProductDetails = {
        ...productDetails,
        price: Number(productDetails.price),
      };

      setProductDetails(updatedProductDetails);
      const uploadResult = await uploadToS3(productImage);

      let sliderUpload;
      if (sliderImages !== null) {
        sliderUpload = await uploadMultipleToS3(sliderImages);
      }

      if (uploadResult.result.ok) {
        updatedProductDetails = {
          ...updatedProductDetails,
          image: uploadResult.location,
          sliderImages: sliderUpload?.length ? sliderUpload : [],
        };
        const response = await axios.post(
          `${process.env.REACT_APP_ADMIN_SERVER}/products`,
          updatedProductDetails
        );
      }
      setProductDetails({
        name: "",
        price: "",
        other: "",
        categoryNumber: null,
        collectionNumber: null,
        tags: "",
        image: "",
        sliderImages: "",
        isPublished: true,
        color: "",
        width: "",
        weight: "",
        length: "",
      });
      setOpenProductModal(false);
      setShowNotification(true);
      setNotification({
        type: "success",
        entity: "product",
        action: "create",
      });
    } catch (error) {
      setShowNotification(true);
      setNotification({
        entity: "product",
        action: "create",
      });
    }
  };

  const handleCategoryImageChange = (newFile) => {
    setCategoryImage(newFile);
    setCategoryDetails({
      ...categoryDetails,
      image: newFile.name,
    });
  };

  const handleCollectionImageChange = (newFile) => {
    setCollectionImage(newFile);
    setCollectionDetails({
      ...collectionDetails,
      image: newFile.name,
    });
  };

  const handleProductImageChange = (newFile) => {
    setProductImage(newFile);
    setProductDetails({
      ...productDetails,
      image: newFile.name,
    });
  };

  const handleSliderImageChange = (newFiles) => {
    setSliderImages(newFiles);
    setProductDetails({
      ...productDetails,
      sliderImages:
        typeof newFiles[0] === "string"
          ? newFiles
          : newFiles.map((file) => file.name),
    });
  };

  const handleCategorySubmit = async () => {
    try {
      let updatedCategoryDetails = {
        ...categoryDetails,
        categoryNumber: categories.length + 1,
      };
      setCategoryDetails(updatedCategoryDetails);
      const uploadResult = await uploadToS3(categoryImage);

      if (uploadResult.result.ok) {
        updatedCategoryDetails = {
          ...updatedCategoryDetails,
          image: uploadResult.location,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_ADMIN_SERVER}/categories`,
          updatedCategoryDetails
        );
      }
      setShowNotification(true);
      setNotification({
        type: "success",
        entity: "category",
        action: "create",
      });
      setCategoryDetails({
        name: "",
        description: "",
        image: "",
        categoryNumber: null,
        isPublished: true,
      });
      setOpenCategoryModal(false);
    } catch (error) {
      setShowNotification(true);
      setNotification({
        entity: "category",
        action: "create",
      });
    }
  };

  const handleCollectionSubmit = async () => {
    try {
      let updatedCollectionDetails = {
        ...collectionDetails,
        collectionNumber: collections.length + 1,
      };

      setCollectionDetails(updatedCollectionDetails);
      const uploadResult = await uploadToS3(collectionImage);

      if (uploadResult.result.ok) {
        updatedCollectionDetails = {
          ...updatedCollectionDetails,
          image: uploadResult.location,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_ADMIN_SERVER}/collections`,
          updatedCollectionDetails
        );
      }
      setCollectionDetails({
        name: "",
        description: "",
        image: "",
        collectionNumber: null,
        isPublished: true,
      });
      setOpenCollectionModal(false);
      setShowNotification(true);
      setNotification({
        type: "success",
        entity: "collection",
        action: "create",
      });
    } catch (error) {
      setShowNotification(true);
      setNotification({
        entity: "collection",
        action: "create",
      });
    }
  };

  const getGreeting = () => {
    const date = new Date();
    const hours = date.getHours();
    if (hours >= 5 && hours <= 12) return "Good Morning";
    if (hours >= 13 && hours <= 17) return "Good Afternoon";
    return "Good Evening";
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
          height: "16vh",
          justifyContent: "space-evenly",
          textAlign: "end",
        }}>
        <Typography variant="h4">{getGreeting()}, Drishti!</Typography>
        <Typography color={"primary"} variant="h5">
          What would you like to do today?
        </Typography>
      </Box>
      <CreateContainer>
        {showNotification && <SendNotifications {...notification} />}
        <ContentBox>
          <Typography className="content-title">Products</Typography>
          <Typography className="content-text">Create a Product</Typography>
          <Button onClick={createProduct}>Create Now</Button>
        </ContentBox>
        <ContentBox>
          <Typography className="content-title">Categories</Typography>
          <Typography className="content-text">Create a Category</Typography>
          <Button onClick={createCategory}>Create Now</Button>
        </ContentBox>
        <ContentBox>
          <Typography className="content-title">Collections</Typography>
          <Typography className="content-text">Create a Collection</Typography>
          <Button onClick={createCollection}>Create Now</Button>
        </ContentBox>
      </CreateContainer>

      {/* Product Modal */}
      <Modal open={openProductModal} onClose={() => setOpenProductModal(false)}>
        <Box
          component={"form"}
          encType="multipart/form-data"
          onSubmit={handleProductSubmit}
          noValidate
          autoComplete="off"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography variant="h3" fontSize={"25px"}>
              <Typography
                display="inline"
                color="primary"
                variant="h3"
                fontSize={"25px"}
                fontWeight={700}>
                Create{" "}
              </Typography>
              New Product
            </Typography>
            <IconButton onClick={() => setOpenProductModal(false)}>
              <CloseIcon fontSize="large" color="primary" />
            </IconButton>
          </Box>
          <Typography color={"primary"} fontWeight={"700"}>
            Name
          </Typography>
          <TextField
            label="Name"
            value={productDetails.name}
            onChange={(e) =>
              setProductDetails({ ...productDetails, name: e.target.value })
            }
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Category
          </Typography>
          <Select
            onChange={(e) =>
              setProductDetails({
                ...productDetails,
                categoryNumber: e.target.value,
              })
            }
            fullWidth
            sx={{ margin: "8px 0" }}>
            <MenuItem value="">Select a category</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category._id} value={category.categoryNumber}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
          <Typography color={"primary"} fontWeight={"700"}>
            Collection
          </Typography>
          <Select
            onChange={(e) =>
              setProductDetails({
                ...productDetails,
                collectionNumber: e.target.value,
              })
            }
            fullWidth>
            <MenuItem value="">Select a collection</MenuItem>
            {collections.map((collection) => (
              <MenuItem
                key={collection._id}
                value={collection.collectionNumber}>
                {collection.name}
              </MenuItem>
            ))}
          </Select>
          <Typography color={"primary"} fontWeight={"700"}>
            Price
          </Typography>
          <TextField
            label="Price"
            placeholder="Enter product price"
            value={productDetails.price}
            onChange={(e) =>
              setProductDetails({ ...productDetails, price: e.target.value })
            }
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Color
          </Typography>
          <TextField
            label="Color"
            value={productDetails.color}
            onChange={(e) =>
              setProductDetails({ ...productDetails, color: e.target.value })
            }
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Width
          </Typography>
          <TextField
            label="Width"
            value={productDetails.width}
            onChange={(e) =>
              setProductDetails({ ...productDetails, width: e.target.value })
            }
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Weight
          </Typography>
          <TextField
            label="Weight"
            value={productDetails.weight}
            onChange={(e) =>
              setProductDetails({ ...productDetails, weight: e.target.value })
            }
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Length
          </Typography>
          <Autocomplete
            freeSolo
            options={lengthOptions}
            value={lengthValue}
            onChange={(event, newValue) => {
              setlengthValue(newValue[0]);
            }}
            inputValue={productDetails.length}
            onInputChange={(event, newValue) =>
              setProductDetails({ ...productDetails, length: newValue[0] })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Length"
                fullWidth
                sx={{ margin: "8px 0" }}
              />
            )}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Other
          </Typography>
          <Autocomplete
            freeSolo
            options={descOptions}
            value={descValue}
            onChange={(event, newValue) => {
              setDescValue(newValue);
            }}
            inputValue={productDetails.other}
            onInputChange={(event, newValue) =>
              setProductDetails({ ...productDetails, other: newValue[0] })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Description"
                placeholder="Enter Description"
                fullWidth
                margin="normal"
              />
            )}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Tags
          </Typography>
          <TextField
            label="Tags"
            value={productDetails.tags}
            onChange={(e) =>
              setProductDetails({ ...productDetails, tags: e.target.value })
            }
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Image
          </Typography>
          <MuiFileInput
            placeholder="Add 1 product image"
            value={productImage}
            className="file-input"
            onChange={handleProductImageChange}
            name="image"
            type="file"
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Slider Images
          </Typography>
          <MuiFileInput
            placeholder="Add 1 or more images"
            value={sliderImages}
            onChange={handleSliderImageChange}
            name="sliderImage"
            type="file"
            multiple
          />
          <Button onClick={handleProductSubmit} fullWidth variant="contained">
            Submit
          </Button>
        </Box>
      </Modal>

      {/* Category Modal */}
      <Modal
        open={openCategoryModal}
        onClose={() => setOpenCategoryModal(false)}>
        <Box
          component={"form"}
          encType="multipart/form-data"
          onSubmit={handleCategorySubmit}
          noValidate
          autoComplete="off"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography variant="h3" fontSize={"25px"}>
              <Typography
                display="inline"
                color="primary"
                variant="h3"
                fontSize={"25px"}
                fontWeight={700}>
                Create{" "}
              </Typography>
              New Category
            </Typography>
            <IconButton onClick={() => setOpenCategoryModal(false)}>
              <CloseIcon fontSize="large" color="primary" />
            </IconButton>
          </Box>
          <Typography color={"primary"} fontWeight={"700"}>
            Name
          </Typography>
          <TextField
            label="Name"
            value={categoryDetails.name}
            onChange={(e) =>
              setCategoryDetails({ ...categoryDetails, name: e.target.value })
            }
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Description
          </Typography>
          <TextField
            label="Description"
            value={categoryDetails.description}
            onChange={(e) =>
              setCategoryDetails({
                ...categoryDetails,
                description: e.target.value,
              })
            }
            multiline
            rows={4}
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Image
          </Typography>
          <FileInputBox
            value={categoryImage}
            className="file-input"
            onChange={handleCategoryImageChange}
            name="image"
            type="file"
          />
          <Button onClick={handleCategorySubmit} fullWidth variant="contained">
            Submit
          </Button>
        </Box>
      </Modal>

      {/* Collection Modal */}
      <Modal
        open={openCollectionModal}
        onClose={() => setOpenCollectionModal(false)}>
        <Box
          component={"form"}
          encType="multipart/form-data"
          onSubmit={handleCollectionSubmit}
          noValidate
          autoComplete="off"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography variant="h3" fontSize={"25px"}>
              <Typography
                display="inline"
                color="primary"
                variant="h3"
                fontSize={"25px"}
                fontWeight={700}>
                Create{" "}
              </Typography>
              New Collection
            </Typography>
            <IconButton onClick={() => setOpenCollectionModal(false)}>
              <CloseIcon fontSize="large" color="primary" />
            </IconButton>
          </Box>
          <Typography color={"primary"} fontWeight={"700"}>
            Name
          </Typography>
          <TextField
            label="Name"
            value={collectionDetails.name}
            onChange={(e) =>
              setCollectionDetails({
                ...collectionDetails,
                name: e.target.value,
              })
            }
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Description
          </Typography>
          <TextField
            label="Description"
            value={collectionDetails.description}
            onChange={(e) =>
              setCollectionDetails({
                ...collectionDetails,
                description: e.target.value,
              })
            }
            multiline
            rows={4}
            fullWidth
            sx={{ margin: "8px 0" }}
          />
          <Typography color={"primary"} fontWeight={"700"}>
            Image
          </Typography>
          <FileInputBox
            value={collectionImage}
            className="file-input"
            onChange={handleCollectionImageChange}
            name="image"
            type="file"
          />
          <Button
            onClick={handleCollectionSubmit}
            fullWidth
            variant="contained">
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AdminHeader;
