import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AdminTable from "../../admin-table/components/AdminTable";

const AdminContent = ({ fullArr }) => {
  const columns = [
    { field: "sr", headerName: "Sr. No.", width: 70 },
    { field: "id", headerName: "ID", width: 250 },
    { field: "name", headerName: "Name", width: 250 },
    { field: "type", headerName: "Type", width: 250 },
    { field: "date", headerName: "Last Modified On", width: 250 },
  ];

  const [myRows, setMyRows] = useState([]);

  useEffect(() => {
    const myArr = fullArr?.map((arr) =>
      arr?.sort((a, b) => new Date(b.updatedAt) - new Date())
    );
    const getType = (obj) => {
      let type = "";
      if (
        Object.hasOwn(obj, "collectionNumber") &&
        Object.hasOwn(obj, "categoryNumber")
      ) {
        type = "Product";
      } else if (Object.hasOwn(obj, "collectionNumber")) {
        type = "Collection";
      } else {
        type = "Category";
      }
      return type;
    };
    const rows = [];
    myArr.map((arr) =>
      arr.forEach((row, index) =>
        rows.push({
          sr: row?._id,
          id: row?._id,
          name: row?.name,
          type: getType(row),
          date: new Date(row?.updatedAt ?? "").toLocaleString("en-IN"),
        })
      )
    );
    setMyRows(rows);
  }, [fullArr]);

  return (
    <Box
      sx={{
        height: "50vh",
        marginTop: "25px",
      }}>
      <Typography
        variant="h5"
        sx={{
          textAlign: "left",
          paddingLeft: "20px",
          paddingBottom: "10px",
        }}>
        Recent Activity
      </Typography>
      {myRows?.length > 0 ? (
        <AdminTable columns={columns} rows={myRows} />
      ) : (
        <Typography>There has been no activity in the last 2 days.</Typography>
      )}
    </Box>
  );
};

export default AdminContent;
