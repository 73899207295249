import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Loading from "../components/loading/Loading";
import ProductContent from "../components/product-content/components/ProductContent";
import ProductImageBox from "../components/product-image/styles";
import SectionContainer from "../components/section-container/components/SectionContainer";
import getAllCharms from "../redux/charmsReducer/action";

const CharmsBracelet = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const { charms, isLoading: charmsIsLoading } = useSelector(
    (store) => store.charmsReducer,
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (charms?.length > 0) {
      return;
    }
    dispatch(getAllCharms());
  }, [charms]);

  const product = {
    name: "Charms Bracelet",
    price: 2850,
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {/* <SEO page={"product"} data={products} /> */}
      {/* Todo: change SEO */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          flexDirection: "column",
        }}>
        <SectionContainer>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              width: "100%",
              margin: "10px 0",
              ".MuiBreadcrumbs-separator": {
                color: "#000",
              },
            }}>
            {!isMobile && (
              <Link
                component={RouterLink}
                underline="hover"
                color="primary.main"
                fontWeight={"700"}
                to={`/store`}>
                Store
              </Link>
            )}
            {!isMobile && (
              <Typography color="text.primary">Charms Bracelet</Typography>
            )}
          </Breadcrumbs>
        </SectionContainer>
        <SectionContainer>
          <Box
            className="product"
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
              },
            }}>
            {isMobile && (
              <Link underline="none" href={`/store`}>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  fontWeight={700}>
                  <KeyboardArrowLeftIcon fontSize="large" />
                  {`Back to Store`}
                </Typography>
              </Link>
            )}
            <ProductImageBox className="product-image">
              <img
                src="https://drijewels-image.s3.ap-south-1.amazonaws.com/Charms_Bracelet.jpg"
                alt="Charms Bracelet"
                onMouseEnter={(e) => {
                  e.target.style.transform = "scale(1.2)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.transform = "scale(1)";
                }}
              />
            </ProductImageBox>
            <ProductContent
              product={product}
              categoryName="store"
              charmsList={charms}
            />
          </Box>
        </SectionContainer>
        <Box width="50%" margin="auto">
          <KeyboardDoubleArrowUpIcon
            fontSize="large"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            sx={{ color: theme.palette.primary.main }}
          />
        </Box>
      </Box>
    </>
  );
};

export default CharmsBracelet;
