/* eslint-disable import/no-extraneous-dependencies */
import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MuiFileInput } from "mui-file-input";

export const StyledSidebar = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1.75),
  width: "100%",
  margin: "0 auto",
  backgroundColor: theme.palette.primary,
  height: theme.spacing(12.5),
}));

export const CreateContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5px",
  height: "24vh",
}));

export const Heading = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.h1,
  fontSize: theme.typography.h1,
  fontWeight: theme.typography.h1,
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#88542220",
  borderRadius: "10px",
  width: "25%",
  height: "100%",
  padding: "10px",
  textAlign: "left",
  marginRight: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  p: {
    marginBottom: "10px",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  ".content-title": {
    fontWeight: 700,
  },
  ".content-text": {
    color: theme.palette.primary.main,
    fontSize: "22px",
    fontWeight: 700,
  },
}));

export const FileInputBox = styled(MuiFileInput)(
  ({ theme }) => `
  & label input {
    opacity: 1!important;
  }
 `
);
