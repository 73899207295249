import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import {
  SwipeableDrawer,
  Box,
  Typography,
  useTheme,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import WindowIcon from "@mui/icons-material/Window";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { StyledSidebar, IconBox } from "../styles/index";
import logo from "../../../images/Djlogo.jpg";

const AdminSidebar = () => {
  const [state, setState] = useState({
    left: false,
    right: false,
    top: false,
    bottom: false,
  });

  const theme = useTheme();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div>
      <ul>
        <li>
          <Link to="/admin">Home</Link>
        </li>
        <li>
          <Link to="/admin/categories">Categories</Link>
        </li>
        <li>
          <Link to="/admin/collections">Collections</Link>
        </li>
        <li>
          <Link to="/admin/products">Products</Link>
        </li>
        <li>
          <Link to="/admin/subscribers">Subscribers</Link>
        </li>
      </ul>
      <ul>
        <li>Settings</li>
        <li>Logout</li>
      </ul>
    </div>
  );

  const [icon, setIcon] = useState(faCircleChevronLeft);

  const handleClick = () => {
    setIcon(faCircleChevronRight);
  };

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Box
      sx={{
        width: "15%",
        height: "100vh",
        backgroundColor: theme.palette.primary.main,
      }}>
      <Box
        className="logo-box"
        sx={{
          img: {
            maxWidth: "80px",
            maxHeight: "80px",
          },
          display: "flex",
          justifyContent: "space-evenly",
        }}>
        <img src={logo} alt="DJ Logo" />
        <Box
          className="logo-textbox"
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}>
          <Typography color={"common.white"} fontWeight={600} fontSize={"17px"}>
            Drishti Jewels
          </Typography>
          <Typography color={"common.white"}>Super Admin</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "5%",
          button: {
            position: "absolute",
            right: "-17px",
            backgroundColor: theme.palette.common.white,
            svg: {
              color: theme.palette.primary.main,
            },
            "&:hover": {
              backgroundColor: theme.palette.common.white,
              svg: {
                color: theme.palette.transparent,
              },
            },
          },
        }}>
        <IconButton onClick={handleClick}>
          <FontAwesomeIcon icon={icon} />
        </IconButton>
      </Box>
      <Box
        height={"50%"}
        sx={{
          a: {
            textDecoration: "none",
          },
        }}>
        <Link to="/admin">
          <IconBox>
            <FontAwesomeIcon icon={faHouse} />
            <Typography variant="h6">Home</Typography>
          </IconBox>
        </Link>
        <Link to="/admin/categories">
          <IconBox>
            <WindowIcon />
            <Typography variant="h6">Categories</Typography>
          </IconBox>
        </Link>
        <Link to="/admin/collections">
          <IconBox>
            <WindowIcon />
            <Typography variant="h6">Collections</Typography>
          </IconBox>
        </Link>
        <Link to="/admin/products">
          <IconBox>
            <WindowIcon />
            <Typography variant="h6">Products</Typography>
          </IconBox>
        </Link>
        <IconBox>
          <WindowIcon />
          <Typography variant="h6">
            <Link to="/admin/subscribers">Subscribers</Link>
          </Typography>
        </IconBox>
      </Box>
      <Box>
        <IconBox>
          <SettingsIcon />
          <Typography variant="h6">Settings</Typography>
        </IconBox>
        <IconBox
          onClick={logout}
          sx={{
            cursor: "pointer",
          }}>
          <LogoutIcon />
          <Typography variant="h6">Logout</Typography>
        </IconBox>
      </Box>
    </Box>
    // <>

    //   <React.Fragment key={"left"}>
    //     <StyledSidebar>
    //     <GiHamburgerMenu
    //       onClick={toggleDrawer("left", true)}
    //       style={{ cursor: 'pointer' }}
    //     />
    //     <SwipeableDrawer
    //       left={"left"}
    //       open={state.left}
    //       onClose={toggleDrawer("left", false)}
    //       onOpen={toggleDrawer("left", true)}
    //     >
    //       {list("left")}
    //     </SwipeableDrawer>
    //     </StyledSidebar>
    //   </React.Fragment>

    // </>
  );
};

export default AdminSidebar;
