import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import {
  deleteCollection,
  editCollection,
  getCollection,
} from "../../../redux/collectionReducer/action";
import AdminTable from "../../admin-table/components/AdminTable";
import AdminSidebar from "../../admin-sidebar/components/AdminSidebar";
import { FileInputBox } from "../../admin-header/styles";
import { uploadToS3, deleteFromS3 } from "../../../config/s3Upload";
import SendNotifications from "../../notifications/components/SendNotifications";

const AdminCollections = () => {
  const dispatch = useDispatch();

  const { collections, isLoading: collectionIsLoading } = useSelector(
    (store) => store.collectionReducer,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getCollection());
  }, []);

  const [rows, setRows] = useState([]);
  const [openCollectionModal, setOpenCollectionModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [collectionDetails, setCollectionDetails] = useState({
    id: "",
    name: "",
    description: "",
    image: "",
    collectionNumber: null,
    isPublished: true,
  });
  const [newCollection, setNewCollection] = useState({
    id: "",
    name: "",
    description: "",
    image: "",
    collectionNumber: null,
    isPublished: true,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [collectionImage, setCollectionImage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    entity: "",
    action: "",
  });

  const newRows = [];
  useEffect(() => {
    if (Array.isArray(collections)) {
      collections.forEach((collection, index) => {
        const data = {
          id: collection._id,
          name: collection.name,
          desc: collection.description,
          image: collection.image,
          update: new Date(collection.updatedAt).toLocaleString("en-IN"),
          collectionNum: collection.collectionNumber,
        };
        newRows.push(data);
      });
    }
    setRows(newRows);
  }, [collections]);

  const createCollection = () => {
    setOpenCreateModal(true);
  };

  const handleCollectionImageChange = (newFile) => {
    setCollectionImage(newFile);
    if (openCreateModal) {
      setNewCollection({
        ...newCollection,
        image: newFile.name,
      });
    } else {
      setCollectionDetails({
        ...collectionDetails,
        image: newFile.name,
      });
    }
  };

  const handleEditCollection = (collection) => {
    setCollectionDetails({
      id: collection.row.id,
      name: collection.row.name,
      description: collection.row.desc,
      image: collection.row.image,
      collectionNumber: collection.row.collectionNum,
      isPublished: true,
    });
    setOpenCollectionModal(true);
  };

  const handleCollectionSubmit = async (type) => {
    if (type === "new") {
      try {
        let updatedCollectionDetails = {
          ...newCollection,
          collectionNumber: collections.length + 1,
        };
        let response;
        setNewCollection(updatedCollectionDetails);
        const uploadResult = await uploadToS3(collectionImage);

        if (uploadResult.result.ok) {
          updatedCollectionDetails = {
            ...updatedCollectionDetails,
            image: uploadResult.location,
          };
          response = await axios.post(
            `${process.env.REACT_APP_ADMIN_SERVER}/collections`,
            updatedCollectionDetails
          );
        }
        setShowNotification(true);
        setNotification({
          type: "success",
          entity: "collection",
          action: "create",
        });
        setNewCollection({
          name: "",
          description: "",
          image: "",
          collectionNumber: null,
          isPublished: true,
        });
        setOpenCreateModal(false);
      } catch (error) {
        setShowNotification(true);
        setNotification({
          entity: "collection",
          action: "create",
        });
      }
    } else {
      try {
        let updatedCollectionDetails = { ...collectionDetails };
        setCollectionDetails(updatedCollectionDetails);
        let uploadResult = "";
        if (collectionImage !== null) {
          uploadResult = await uploadToS3(collectionImage);
          if (uploadResult.result.ok) {
            updatedCollectionDetails = {
              ...updatedCollectionDetails,
              image: uploadResult.location,
            };
          }
        }
        dispatch(
          editCollection(collectionDetails.id, updatedCollectionDetails)
        );
        setCollectionDetails({
          id: "",
          name: "",
          description: "",
          image: "",
          collectionNumber: null,
          isPublished: true,
        });
        setOpenCollectionModal(false);
        setShowNotification(true);
        setNotification({
          type: "success",
          entity: "collection",
          action: "update",
        });
      } catch (error) {
        setShowNotification(true);
        setNotification({
          entity: "collection",
          action: "update",
        });
      }
    }
  };

  const handleDeleteCollection = (collection) => {
    setCollectionDetails({
      id: collection.row.id,
      name: collection.row.name,
      description: collection.row.desc,
      image: collection.row.image,
      collectionNumber: collection.row.collectionNum,
      isPublished: true,
    });
    setIsDeleteModalOpen(true);
  };

  const columns = [
    {
      field: "collectionNum",
      headerName: "Collection No.",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id",
      headerName: "ID",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "desc",
      headerName: "Description",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: "Image",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "update",
      headerName: "Last Modified On",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "edit",
      headerName: "Edit Collection",
      width: 100,
      renderCell: (params) => (
        <Box
          onClick={() => handleEditCollection(params)}
          sx={{
            cursor: "pointer",
          }}>
          <EditIcon color="primary" />
        </Box>
      ),
      align: "center",
    },
    {
      field: "delete",
      headerName: "Delete Collection",
      width: 150,
      renderCell: (params) => (
        <Box
          onClick={() => handleDeleteCollection(params)}
          sx={{
            cursor: "pointer",
          }}>
          <DeleteIcon color="primary" />
        </Box>
      ),
      align: "center",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}>
      <AdminSidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "85%",
          height: "45%",
          justifyContent: "space-evenly",
          textAlign: "center",
        }}>
        {showNotification && <SendNotifications {...notification} />}
        <Box>
          <Typography variant="h4" color={"primary"}>
            Collections
          </Typography>
          <Typography variant="h5">Find all your collections here.</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
          <Button onClick={createCollection} variant="contained">
            Create New Collection
          </Button>
          <Modal
            open={openCreateModal}
            onClose={() => setOpenCreateModal(false)}>
            <Box
              component={"form"}
              encType="multipart/form-data"
              onSubmit={() => {
                handleCollectionSubmit("new");
              }}
              noValidate
              autoComplete="off"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                maxHeight: "80vh",
                overflowY: "auto",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography variant="h3" fontSize={"25px"}>
                  <Typography
                    display="inline"
                    color="primary"
                    variant="h3"
                    fontSize={"25px"}
                    fontWeight={700}>
                    Create{" "}
                  </Typography>
                  New Collection
                </Typography>
                <IconButton onClick={() => setOpenCreateModal(false)}>
                  <CloseIcon fontSize="large" color="primary" />
                </IconButton>
              </Box>
              <Typography color={"primary"} fontWeight={"700"}>
                Name
              </Typography>
              <TextField
                label="Name"
                value={newCollection.name}
                onChange={(e) =>
                  setNewCollection({ ...newCollection, name: e.target.value })
                }
                fullWidth
                sx={{ margin: "8px 0" }}
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Description
              </Typography>
              <TextField
                label="Description"
                value={newCollection.description}
                onChange={(e) =>
                  setNewCollection({
                    ...newCollection,
                    description: e.target.value,
                  })
                }
                multiline
                rows={4}
                fullWidth
                sx={{ margin: "8px 0" }}
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Image
              </Typography>
              <FileInputBox
                value={collectionImage}
                className="file-input"
                onChange={handleCollectionImageChange}
                name="image"
                type="file"
              />
              <Button
                onClick={() => {
                  handleCollectionSubmit("new");
                }}
                fullWidth
                variant="contained">
                Submit
              </Button>
            </Box>
          </Modal>
        </Box>
        {rows.length > 0 && (
          <>
            <AdminTable columns={columns} rows={rows} />
            <Modal
              open={openCollectionModal}
              onClose={() => setOpenCollectionModal(false)}>
              <Box
                component={"form"}
                encType="multipart/form-data"
                onSubmit={handleCollectionSubmit}
                noValidate
                autoComplete="off"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Typography variant="h3" fontSize={"25px"}>
                    <Typography
                      display="inline"
                      color="primary"
                      variant="h3"
                      fontSize={"25px"}
                      fontWeight={700}>
                      Edit{" "}
                    </Typography>
                    Collection
                  </Typography>
                  <IconButton onClick={() => setOpenCollectionModal(false)}>
                    <CloseIcon fontSize="large" color="primary" />
                  </IconButton>
                </Box>
                <Typography color={"primary"}>Name</Typography>
                <TextField
                  label="Name"
                  value={collectionDetails.name}
                  onChange={(e) =>
                    setCollectionDetails({
                      ...collectionDetails,
                      name: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ margin: "8px 0" }}
                />
                <Typography color={"primary"}>Description</Typography>
                <TextField
                  label="Description"
                  value={collectionDetails.description}
                  onChange={(e) =>
                    setCollectionDetails({
                      ...collectionDetails,
                      description: e.target.value,
                    })
                  }
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ margin: "8px 0" }}
                />
                <Typography color={"primary"}>Image</Typography>
                <Tooltip
                  title="This is the current image. If you wish to change it, upload a new one below"
                  arrow>
                  <Typography sx={{ cursor: "pointer" }}>
                    {collectionDetails.image}
                  </Typography>
                </Tooltip>
                <FileInputBox
                  value={collectionImage}
                  className="file-input"
                  onChange={handleCollectionImageChange}
                  name="image"
                  type="file"
                />
                <Button
                  onClick={handleCollectionSubmit}
                  fullWidth
                  variant="contained">
                  Submit
                </Button>
              </Box>
            </Modal>
            <Modal
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "35%",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                  }}>
                  <Typography variant="h3">Are you sure?</Typography>
                  <IconButton
                    onClick={() => setIsDeleteModalOpen(false)}
                    sx={{
                      position: "absolute",
                      right: "1px",
                    }}>
                    <CloseIcon />
                  </IconButton>
                  <Typography>
                    Are you sure you want to delete
                    <Typography display={"inline"} fontWeight={"700"}>
                      {" "}
                      {collectionDetails.name}{" "}
                    </Typography>
                    collection?
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      width: "50%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}>
                    <Button
                      color="success"
                      onClick={async () => {
                        const deleteResult = await deleteFromS3(
                          collectionDetails.image
                        );
                        if (deleteResult.ok) {
                          dispatch(deleteCollection(collectionDetails.id));
                          setIsDeleteModalOpen(false);
                          setShowNotification(true);
                          setNotification({
                            type: "success",
                            entity: "collection",
                            action: "delete",
                          });
                        } else {
                          setShowNotification(true);
                          setNotification({
                            entity: "collection",
                            action: "delete",
                          });
                        }
                      }}
                      variant="contained">
                      Yes
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        setIsDeleteModalOpen(false);
                      }}>
                      No
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdminCollections;
