import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Home from "./Home";
import Contact from "./Contact";
import Store from "./Store";
import Admin from "./Admin";
import Category from "./Category";
import Product from "./Product";
import Footer from "../components/footer/components/Footer";
import Navbar from "../components/navbar/components/Navbar";
import Bestsellers from "../components/bestsellers/components/Bestsellers";
import Search from "./Search";
import PageNotFound from "./PageNotFound";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";
import Founder from "./Founder";
import FAQ from "./FAQ";
import Login from "./Login";
import AdminCategories from "../components/admin-categories/components/AdminCategories";
import AdminCollections from "../components/admin-collections/components/AdminCollections";
import AdminProducts from "../components/admin-products/components/AdminProducts";
import PrivateRoute from "../components/private-route/PrivateRoute";
import CharmsBracelet from "./CharmsBracelet";

const MainRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {location.pathname !== "/admin" &&
        location.pathname !== "/admin/categories" &&
        location.pathname !== "/admin/collections" &&
        location.pathname !== "/admin/products" &&
        location.pathname !== "/admin/subscribers" && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/store" element={<Store />} />
        <Route path="/search" element={<Search />} />
        <Route path="/bestsellers" element={<Bestsellers />} />
        <Route path="/categories/:id" element={<Category />} />
        <Route path="/collections/:id" element={<Category />} />
        <Route path="/products/:id" element={<Product />} />
        <Route path="/charms" element={<CharmsBracelet />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/founder" element={<Founder />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/categories"
          element={
            <PrivateRoute>
              <AdminCategories />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/collections"
          element={
            <PrivateRoute>
              <AdminCollections />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/products"
          element={
            <PrivateRoute>
              <AdminProducts />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/subscribers"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {location.pathname !== "/admin" &&
        location.pathname !== "/admin/categories" &&
        location.pathname !== "/admin/collections" &&
        location.pathname !== "/admin/products" &&
        location.pathname !== "/admin/subscribers" && <Footer />}
    </>
  );
};

export default MainRoutes;
