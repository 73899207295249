import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

function AuthContextProvider(props) {
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const navigate = useNavigate();

  const loginUser = async () => {
    try {
      const auth = localStorage.getItem("isAuth");
      setIsAuth(auth);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErr(true);
      console.log(error);
    }
  };

  useEffect(() => {
    loginUser();
  }, []);

  return (
    <AuthContext.Provider value={{ isAuth, loginUser, loading, err }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
