import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContextProvider";

function PrivateRoute(props) {
  const { isAuth, loginUser, loading, err } = useContext(AuthContext);

  const navigate = useNavigate();

  if (loading) {
    return <h1>Loading...</h1>;
  }

  if (!isAuth) {
    navigate("/login");
    return null;
  }

  return props.children;
}

export default PrivateRoute;
