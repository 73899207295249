import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const AdminTable = ({ columns, rows }) => (
  <Box sx={{ width: "100%" }}>
    <DataGrid
      rows={rows}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      onCellDoubleClick={(params, event, details) => {
        if (params.row.type === "Product") {
          window.location.replace(`/admin/products`);
        }
        if (params.row.type === "Category") {
          window.location.replace(`/admin/categories`);
        }
        if (params.row.type === "Collection") {
          window.location.replace(`/admin/collections`);
        }
      }}
      pageSizeOptions={[10, 25, 50]}
    />
  </Box>
);

export default AdminTable;
