import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import {
  deleteCategory,
  editCategory,
  getCategory,
} from "../../../redux/categoryReducer/action";
import AdminTable from "../../admin-table/components/AdminTable";
import AdminSidebar from "../../admin-sidebar/components/AdminSidebar";
import { FileInputBox } from "../../admin-header/styles";
import { uploadToS3, deleteFromS3 } from "../../../config/s3Upload";
import SendNotifications from "../../notifications/components/SendNotifications";

const AdminCategories = () => {
  const dispatch = useDispatch();

  const { categories, isLoading: categoryIsLoading } = useSelector(
    (store) => store.categoryReducer,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getCategory());
  }, []);

  const [rows, setRows] = useState([]);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState({
    id: "",
    name: "",
    description: "",
    image: "",
    categoryNumber: null,
    isPublished: true,
  });
  const [newCategory, setNewCategory] = useState({
    id: "",
    name: "",
    description: "",
    image: "",
    categoryNumber: null,
    isPublished: true,
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    entity: "",
    action: "",
  });

  const newRows = [];
  useEffect(() => {
    if (Array.isArray(categories)) {
      categories.forEach((category, index) => {
        const data = {
          id: category._id,
          name: category.name,
          desc: category.description,
          image: category.image,
          update: new Date(category.updatedAt).toLocaleString("en-IN"),
          categoryNum: category.categoryNumber,
        };
        newRows.push(data);
      });
    }
    setRows(newRows);
  }, [categories]);

  const createCategory = () => {
    setOpenCreateModal(true);
  };

  const handleCategoryImageChange = (newFile) => {
    setCategoryImage(newFile);
    if (openCreateModal) {
      setNewCategory({
        ...newCategory,
        image: newFile.name,
      });
    } else {
      setCategoryDetails({
        ...categoryDetails,
        image: newFile.name,
      });
    }
  };

  const handleEditCategory = (category) => {
    setCategoryDetails({
      id: category.row.id,
      name: category.row.name,
      description: category.row.desc,
      image: category.row.image,
      categoryNumber: category.row.categoryNum,
      isPublished: true,
    });
    setOpenCategoryModal(true);
  };

  const handleCategorySubmit = async (type) => {
    if (type === "new") {
      try {
        let updatedCategoryDetails = {
          ...newCategory,
          categoryNumber: categories.length + 1,
        };
        setNewCategory(updatedCategoryDetails);
        const uploadResult = await uploadToS3(categoryImage);

        if (uploadResult.result.ok) {
          updatedCategoryDetails = {
            ...updatedCategoryDetails,
            image: uploadResult.location,
          };
          const response = await axios.post(
            `${process.env.REACT_APP_ADMIN_SERVER}/categories`,
            updatedCategoryDetails
          );
        }
        setShowNotification(true);
        setNotification({
          type: "success",
          entity: "category",
          action: "create",
        });
        setNewCategory({
          name: "",
          description: "",
          image: "",
          categoryNumber: null,
          isPublished: true,
        });
        setOpenCreateModal(false);
      } catch (error) {
        setShowNotification(true);
        setNotification({
          entity: "category",
          action: "create",
        });
      }
    } else {
      try {
        let updatedCategoryDetails = { ...categoryDetails };
        setCategoryDetails(updatedCategoryDetails);
        let uploadResult = "";
        if (categoryImage !== null) {
          uploadResult = await uploadToS3(categoryImage);
          if (uploadResult.result.ok) {
            updatedCategoryDetails = {
              ...updatedCategoryDetails,
              image: uploadResult.location,
            };
          }
        }
        dispatch(editCategory(categoryDetails.id, updatedCategoryDetails));
        setCategoryDetails({
          id: "",
          name: "",
          description: "",
          image: "",
          categoryNumber: null,
          isPublished: true,
        });
        setOpenCategoryModal(false);
        setShowNotification(true);
        setNotification({
          type: "success",
          entity: "category",
          action: "update",
        });
      } catch (error) {
        setShowNotification(true);
        setNotification({
          entity: "category",
          action: "update",
        });
      }
    }
  };

  const handleDeleteCategory = (category) => {
    setCategoryDetails({
      id: category.row.id,
      name: category.row.name,
      description: category.row.desc,
      image: category.row.image,
      categoryNumber: category.row.categoryNum,
      isPublished: true,
    });
    setIsDeleteModalOpen(true);
  };

  const columns = [
    {
      field: "categoryNum",
      headerName: "Category No.",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id",
      headerName: "ID",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "desc",
      headerName: "Description",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: "Image",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "update",
      headerName: "Last Modified On",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "edit",
      headerName: "Edit Category",
      width: 100,
      renderCell: (params) => (
        <Box
          onClick={() => handleEditCategory(params)}
          sx={{
            cursor: "pointer",
          }}>
          <EditIcon color="primary" />
        </Box>
      ),
      align: "center",
    },
    {
      field: "delete",
      headerName: "Delete Category",
      width: 150,
      renderCell: (params) => (
        <Box
          onClick={() => handleDeleteCategory(params)}
          sx={{
            cursor: "pointer",
          }}>
          <DeleteIcon color="primary" />
        </Box>
      ),
      align: "center",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}>
      <AdminSidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "85%",
          height: "45%",
          justifyContent: "space-evenly",
          textAlign: "center",
        }}>
        {showNotification && <SendNotifications {...notification} />}
        <Box>
          <Typography variant="h4" color={"primary"}>
            Categories
          </Typography>
          <Typography variant="h5">Find all your categories here.</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
          <Button onClick={createCategory} variant="contained">
            Create New Category
          </Button>
        </Box>
        <Modal open={openCreateModal} onClose={() => setOpenCreateModal(false)}>
          <Box
            component={"form"}
            encType="multipart/form-data"
            onSubmit={() => {
              handleCategorySubmit("new");
            }}
            noValidate
            autoComplete="off"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              maxHeight: "80vh",
              overflowY: "auto",
            }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <Typography variant="h3" fontSize={"25px"}>
                <Typography
                  display="inline"
                  color="primary"
                  variant="h3"
                  fontSize={"25px"}
                  fontWeight={700}>
                  Create{" "}
                </Typography>
                New Category
              </Typography>
              <IconButton onClick={() => setOpenCreateModal(false)}>
                <CloseIcon fontSize="large" color="primary" />
              </IconButton>
            </Box>
            <Typography color={"primary"} fontWeight={"700"}>
              Name
            </Typography>
            <TextField
              label="Name"
              value={newCategory.name}
              onChange={(e) =>
                setNewCategory({ ...newCategory, name: e.target.value })
              }
              fullWidth
              sx={{ margin: "8px 0" }}
            />
            <Typography color={"primary"} fontWeight={"700"}>
              Description
            </Typography>
            <TextField
              label="Description"
              value={newCategory.description}
              onChange={(e) =>
                setNewCategory({
                  ...newCategory,
                  description: e.target.value,
                })
              }
              multiline
              rows={4}
              fullWidth
              sx={{ margin: "8px 0" }}
            />
            <Typography color={"primary"} fontWeight={"700"}>
              Image
            </Typography>
            <FileInputBox
              value={categoryImage}
              className="file-input"
              onChange={handleCategoryImageChange}
              name="image"
              type="file"
            />
            <Button
              onClick={() => {
                handleCategorySubmit("new");
              }}
              fullWidth
              variant="contained">
              Submit
            </Button>
          </Box>
        </Modal>
        {rows.length > 0 && (
          <>
            <AdminTable columns={columns} rows={rows} />
            <Modal
              open={openCategoryModal}
              onClose={() => setOpenCategoryModal(false)}>
              <Box
                component={"form"}
                encType="multipart/form-data"
                onSubmit={handleCategorySubmit}
                noValidate
                autoComplete="off"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Typography variant="h3" fontSize={"25px"}>
                    <Typography
                      display="inline"
                      color="primary"
                      variant="h3"
                      fontSize={"25px"}
                      fontWeight={700}>
                      Edit{" "}
                    </Typography>
                    Category
                  </Typography>
                  <IconButton onClick={() => setOpenCategoryModal(false)}>
                    <CloseIcon fontSize="large" color="primary" />
                  </IconButton>
                </Box>
                <Typography color={"primary"}>Name</Typography>
                <TextField
                  label="Name"
                  value={categoryDetails.name}
                  onChange={(e) =>
                    setCategoryDetails({
                      ...categoryDetails,
                      name: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ margin: "8px 0" }}
                />
                <Typography color={"primary"}>Description</Typography>
                <TextField
                  label="Description"
                  value={categoryDetails.description}
                  onChange={(e) =>
                    setCategoryDetails({
                      ...categoryDetails,
                      description: e.target.value,
                    })
                  }
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ margin: "8px 0" }}
                />
                <Typography color={"primary"}>Image</Typography>
                <Tooltip
                  title="This is the current image. If you wish to change it, upload a new one below"
                  arrow>
                  <Typography sx={{ cursor: "pointer" }}>
                    {categoryDetails.image}
                  </Typography>
                </Tooltip>
                <FileInputBox
                  value={categoryImage}
                  className="file-input"
                  onChange={handleCategoryImageChange}
                  name="image"
                  type="file"
                />
                <Button
                  onClick={handleCategorySubmit}
                  fullWidth
                  variant="contained">
                  Submit
                </Button>
              </Box>
            </Modal>
            <Modal
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "35%",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                  }}>
                  <Typography variant="h3">Are you sure?</Typography>
                  <IconButton
                    onClick={() => setIsDeleteModalOpen(false)}
                    sx={{
                      position: "absolute",
                      right: "1px",
                    }}>
                    <CloseIcon />
                  </IconButton>
                  <Typography>
                    Are you sure you want to delete
                    <Typography display={"inline"} fontWeight={"700"}>
                      {" "}
                      {categoryDetails.name}{" "}
                    </Typography>
                    category?
                  </Typography>
                  <Typography>
                    Deleting this category will also delete the image
                    associated.
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      width: "50%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}>
                    <Button
                      color="success"
                      onClick={async () => {
                        const deleteResult = await deleteFromS3(
                          categoryDetails.image
                        );
                        if (deleteResult.ok) {
                          dispatch(deleteCategory(categoryDetails.id));
                          setIsDeleteModalOpen(false);
                          setShowNotification(true);
                          setNotification({
                            type: "success",
                            entity: "category",
                            action: "delete",
                          });
                        } else {
                          setShowNotification(true);
                          setNotification({
                            entity: "category",
                            action: "delete",
                          });
                        }
                      }}
                      variant="contained">
                      Yes
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        setIsDeleteModalOpen(false);
                      }}>
                      No
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdminCategories;
