const individualCat = {
  _id: "6475d4e5cf3fa045768be79a",
  name: "Rings",
  description:
    "Indulge in pure elegance with our custom-made, luxurious rings designed for universal wear. Handcrafted from exquisite sterling silver 925 and adorned with dazzling Swarovski diamonds, these rings are a symbol of refined beauty, tailored exclusively to your desires.",
  image: "https://drijewels.com/admin/uploads/tinywow_DSC_3534-01_6775224.webp",
  isPublished: true,
  __v: 0,
  createdAt: "2023-05-30T10:50:13.753Z",
  updatedAt: "2023-05-30T10:50:13.753Z",
  products: [
    {
      _id: "6486179a18d7e76f8f4c2442",
      name: "Solitaire Heart Ring",
      description: "*This does not include watch with it",
      price: 2500,
      category: "6475d4e5cf3fa045768be79a",
      image: "tinywow_DSC_3534-01_6775224.webp",
      sliderImages: [
        "tinywow_DSC_3534-01_6775224.1665821139.webp",
        "tinywow_DSC_3536-01_6776950.1665821139.webp",
        "tinywow_DSC_3594-01_6776970.1665821139.webp",
      ],
      stock: 1,
      tags: ["heart ring", "solitaire ring"],
      isPublished: true,
      colour: "White",
      width: "12mm",
      weight: "2.8gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:06.489Z",
      updatedAt: "2023-06-11T18:51:06.489Z",
    },
    {
      _id: "6486179a18d7e76f8f4c2443",
      name: "Baguette Band",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "tinywow_DSC_3620-01_6777065.webp",
      sliderImages: [],
      stock: 1,
      tags: ["bands", "womens band", "diamond bands"],
      isPublished: true,
      colour: "White",
      width: "4.5mm",
      weight: "4gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:06.490Z",
      updatedAt: "2023-06-11T18:51:06.490Z",
    },
    {
      _id: "6486179a18d7e76f8f4c2444",
      name: "Trio Pear Ring",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663916466DSC_3662-01.jpeg",
      sliderImages: ["1663916466DSC_3636-01.jpeg,1663916466DSC_3662-01.jpeg"],
      stock: 1,
      tags: ["solitaire rings", "pear rings", "pear shape"],
      isPublished: true,
      colour: "White",
      width: "13mm",
      weight: "4gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:06.491Z",
      updatedAt: "2023-06-11T18:51:06.491Z",
    },
    {
      _id: "6486179a18d7e76f8f4c2446",
      name: "Double Cross Band",
      price: 2300,
      category: "6475d4e5cf3fa045768be79a",
      image: "tinywow_image0 (59)_6777582.webp",
      sliderImages: [
        "tinywow_image0 (59)_6777582.1665822523.webp",
        "tinywow_image1 (23)_6777611.1665822523.webp",
        "tinywow_image2 (16)_6777627.1665822523.webp",
      ],
      stock: 1,
      tags: ["bands"],
      isPublished: true,
      colour: "White",
      width: "4mm",
      weight: "2.75gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:06.492Z",
      updatedAt: "2023-06-11T18:51:06.492Z",
    },
    {
      _id: "6486179a18d7e76f8f4c2447",
      name: "Classic Solitaire Ring",
      price: 2300,
      category: "6475d4e5cf3fa045768be79a",
      image: "tinywow_DSC_3671-01_6777721.webp",
      sliderImages: [
        "tinywow_DSC_3641-01_6777704.1665822845.webp",
        "tinywow_DSC_3671-01_6777721.1665822845.webp",
      ],
      stock: 1,
      tags: ["solitaire rings", "simple rings", "best seller"],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "2.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:06.492Z",
      updatedAt: "2023-06-11T18:51:06.492Z",
    },
    {
      _id: "648617c8e7d8f3cee6e87931",
      name: "Trio Solitaire",
      price: 2850,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663927363DSC_2208-01.JPEG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "2.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:52.401Z",
      updatedAt: "2023-06-11T18:51:52.401Z",
    },
    {
      _id: "648617c8e7d8f3cee6e87932",
      name: "Audrey Band",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663927621DSC_1878-01.jpeg",
      sliderImages: [
        "1663927621DSC_1875-01.jpeg",
        "1663927621DSC_1878-01.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:52.402Z",
      updatedAt: "2023-06-11T18:51:52.402Z",
    },
    {
      _id: "648617c8e7d8f3cee6e87933",
      name: "Chain Band",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663927676DSC_2136-01.JPEG",
      sliderImages: ["1663927676DSC_2141-01.JPEG"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:52.402Z",
      updatedAt: "2023-06-11T18:51:52.402Z",
    },
    {
      _id: "648617c8e7d8f3cee6e8793e",
      name: "Oval Solitaire Band",
      price: 3600,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663929067DSC_2071-01.jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "16mm",
      weight: "5gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:52.404Z",
      updatedAt: "2023-06-11T18:51:52.404Z",
    },
    {
      _id: "648617c8e7d8f3cee6e87942",
      name: "Lilac Ring",
      price: 2300,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663929419DSC_1898-01.jpeg",
      sliderImages: [
        "1663929419DSC_1904-01.jpeg",
        "1663929419DSC_1905-01.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "2.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:52.405Z",
      updatedAt: "2023-06-11T18:51:52.405Z",
    },
    {
      _id: "648617c8e7d8f3cee6e87943",
      name: "Chain Band",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663929465DSC_2158-01.JPEG",
      sliderImages: ["1663929465DSC_2154-01.JPEG"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "7.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:52.405Z",
      updatedAt: "2023-06-11T18:51:52.405Z",
    },
    {
      _id: "648617c8e7d8f3cee6e87944",
      name: "Three Tone Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "DSC_2166-01.JPEG",
      sliderImages: ["1663929530DSC_2166-01.JPEG"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "13.8mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:51:52.405Z",
      updatedAt: "2023-06-11T18:51:52.405Z",
    },
    {
      _id: "6486189eab0aaf79f453e4c4",
      name: "Baguette Ring",
      price: 4000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663947071IMG_7198.jpg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4gm",
      __v: 0,
      createdAt: "2023-06-11T18:55:26.038Z",
      updatedAt: "2023-06-11T18:55:26.038Z",
    },
    {
      _id: "6486189eab0aaf79f453e4c5",
      name: "Multi Overlapped Band",
      price: 5500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663947139IMG_7207.jpg",
      sliderImages: ["166394713947C2B9AE-E068-47C4-96B1-FCFA6AE4B67D.JPG"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "15mm",
      weight: "8gm",
      __v: 0,
      createdAt: "2023-06-11T18:55:26.039Z",
      updatedAt: "2023-06-11T18:55:26.039Z",
    },
    {
      _id: "6486189eab0aaf79f453e4c6",
      name: "Diamond band",
      price: 2850,
      category: "6475d4e5cf3fa045768be79a",
      image: "IMG_7219 (1).jpg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "Rose",
      width: "5mm",
      weight: "2.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:55:26.039Z",
      updatedAt: "2023-06-11T18:55:26.039Z",
    },
    {
      _id: "6486189eab0aaf79f453e4c7",
      name: "Simple Cross Band",
      price: 2300,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663947501IMG_7396.jpg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "Rose",
      width: "6mm",
      weight: "3.52gm",
      __v: 0,
      createdAt: "2023-06-11T18:55:26.039Z",
      updatedAt: "2023-06-11T18:55:26.039Z",
    },
    {
      _id: "64861925ff3c0c6fd30c743d",
      name: "Minimal Baguette Ring",
      price: 2000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663949947IMG_2629.jpg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "5mm",
      weight: "2gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.753Z",
      updatedAt: "2023-06-11T18:57:41.753Z",
    },
    {
      _id: "64861925ff3c0c6fd30c743e",
      name: "Minimal Diamond Ring",
      price: 2200,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663949979IMG_2627.jpg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "3mm",
      weight: "2gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.753Z",
      updatedAt: "2023-06-11T18:57:41.753Z",
    },
    {
      _id: "64861925ff3c0c6fd30c743f",
      name: "Halo Baguette Ring",
      price: 2300,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663950029IMG_2625.jpg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "5.5mm",
      weight: "2gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.754Z",
      updatedAt: "2023-06-11T18:57:41.754Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7440",
      name: "Parallel Baguette Band",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663951099IMG_3023.JPG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "5mm",
      weight: "3gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.754Z",
      updatedAt: "2023-06-11T18:57:41.754Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7441",
      name: "Radiant Diamond Band",
      price: 2850,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663951167IMG_3022.JPG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "5mm",
      weight: "3gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.754Z",
      updatedAt: "2023-06-11T18:57:41.754Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7442",
      name: "Arley Ring",
      price: 2200,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663951376BA4B178F-1E75-4ABF-AEF4-E862C2C16158.JPEG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "Rose",
      width: "6mm",
      weight: "2.3gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.754Z",
      updatedAt: "2023-06-11T18:57:41.754Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7443",
      name: "Cilla Ring - 3 Tone",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "166395145644FB8151-C21F-49B8-9609-5E6A576D58EE.JPEG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White, Rose, Yellow",
      width: "18mm",
      weight: "4gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.754Z",
      updatedAt: "2023-06-11T18:57:41.754Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7444",
      name: "Jelica Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "166395148001D23ECE-7AD4-4F9A-9A03-611E41C8A3B2.JPG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.754Z",
      updatedAt: "2023-06-11T18:57:41.754Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7445",
      name: "Dia Solitaire Band",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "16639515256ef2e474-08a9-4afc-aadb-bdbbb6314fe7.JPG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "5gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.754Z",
      updatedAt: "2023-06-11T18:57:41.754Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7446",
      name: "kiel Ring",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663951651927453DD-1416-413E-9EA9-43B50F77CBB1.JPEG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "Rose",
      width: "8mm",
      weight: "3.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.754Z",
      updatedAt: "2023-06-11T18:57:41.754Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7447",
      name: "Double Ring Cocktail Ring",
      price: 15500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663951691c4a72b49-b8a4-4696-ae7b-9b0232b564c2.JPG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "Rose",
      width: "4inches",
      weight: "20gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.755Z",
      updatedAt: "2023-06-11T18:57:41.755Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7448",
      name: "Solitaire Cocktail Ring",
      price: 5900,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663951741IMG_1160.JPG",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "22mm",
      weight: "8.9gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.755Z",
      updatedAt: "2023-06-11T18:57:41.755Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7449",
      name: "Solitaire Cross Cocktail Band",
      price: 5500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663952451image19.jpeg",
      sliderImages: ["1663952451image17.jpeg", "1663952451image18.jpeg"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "Rose, White",
      width: "20mm",
      weight: "6gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.755Z",
      updatedAt: "2023-06-11T18:57:41.755Z",
    },
    {
      _id: "64861925ff3c0c6fd30c744a",
      name: "Solitaire Band",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663952515image16.jpeg",
      sliderImages: [
        "1663952515image11.jpeg",
        "1663952515image15.jpeg",
        "1663952515image16.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "Yellow",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.755Z",
      updatedAt: "2023-06-11T18:57:41.755Z",
    },
    {
      _id: "64861925ff3c0c6fd30c744b",
      name: "Flat Pave Band",
      price: 4500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663952585image12.jpeg",
      sliderImages: [
        "1663952585image12.jpeg",
        "1663952585image13.jpeg",
        "1663952585image14.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "6mm",
      weight: "5gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.755Z",
      updatedAt: "2023-06-11T18:57:41.755Z",
    },
    {
      _id: "64861925ff3c0c6fd30c744c",
      name: "Rian Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663952833image7 (1).jpeg",
      sliderImages: ["1663952833image4 (7).jpeg", "1663952833image7 (1).jpeg"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.755Z",
      updatedAt: "2023-06-11T18:57:41.755Z",
    },
    {
      _id: "64861925ff3c0c6fd30c744d",
      name: "Via Baguette Ring",
      price: 3300,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663952979image3 (10).jpeg",
      sliderImages: ["1663952979image3 (10).jpeg", "1663952979image6 (1).jpeg"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "13mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.755Z",
      updatedAt: "2023-06-11T18:57:41.755Z",
    },
    {
      _id: "64861925ff3c0c6fd30c744e",
      name: "Radiant Diamond Eternity Band",
      price: 2500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663953803image0 (60).jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "5mm",
      weight: "3.62gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c744f",
      name: "Lisa Eternity Band",
      price: 2300,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663953892image9 (1).jpeg",
      sliderImages: ["1663953892image11.jpeg"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "5mm",
      weight: "2.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7450",
      name: "Via Rose Band",
      price: 4500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663953980image12.jpeg",
      sliderImages: [
        "1663953980image12.jpeg",
        "1663953980image13.jpeg",
        "1663953980image14.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "13mm",
      weight: "5gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7451",
      name: "Zen Minimal Ring",
      price: 1800,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663954101image26.jpeg",
      sliderImages: [
        "1663954101image24.jpeg",
        "1663954101image25.jpeg",
        "1663954101image26.jpeg",
        "1663954101image29.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "5.10mm",
      weight: "1.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7452",
      name: "Tana Cocktail Solitaire Band",
      price: 4500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663954145image27.jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "10mm",
      weight: "5.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7453",
      name: "Rian Solitaire Minimal Ring",
      price: 2500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663954193image28.jpeg",
      sliderImages: ["1663954193image29.jpeg"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "6mm",
      weight: "2.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7454",
      name: "Custom Wifey Ring",
      price: 2500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663954623image16.jpeg",
      sliderImages: [
        "1663954623image16.jpeg",
        "1663954623image17.jpeg",
        "1663954623image20.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "11mm",
      weight: "3.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7455",
      name: "Drop Stack Set",
      price: 5500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663954724image22.jpeg",
      sliderImages: [
        "1663954724image21.jpeg",
        "1663954724image22.jpeg",
        "1663954724image23.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "20mm",
      weight: "6.52gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7456",
      name: "Solitaire Stack Set",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663954834image25.jpeg",
      sliderImages: [
        "1663954834image24.jpeg",
        "1663954834image25.jpeg",
        "1663954834image26.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7457",
      name: "Pave Band Stack Set",
      price: 4500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663954929image15.jpeg",
      sliderImages: [
        "1663954929image15.jpeg",
        "1663954929image18.jpeg",
        "1663954929image19.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7458",
      name: "Cross Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663955041image12.jpeg",
      sliderImages: [
        "1663955041image12.jpeg",
        "1663955041image13.jpeg",
        "1663955041image14.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "25mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.756Z",
      updatedAt: "2023-06-11T18:57:41.756Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7459",
      name: "Random Set Baguette Ring",
      price: 2500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663955138image5 (5).jpeg",
      sliderImages: [
        "1663955138image4 (7).jpeg",
        "1663955138image5 (5).jpeg",
        "1663955138image8.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "3.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.757Z",
      updatedAt: "2023-06-11T18:57:41.757Z",
    },
    {
      _id: "64861925ff3c0c6fd30c745a",
      name: "Solitaire Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663955428image0 (59).jpeg",
      sliderImages: [
        "1663955428image0 (59).jpeg",
        "1663955428image1 (23).jpeg",
        "1663955428image2 (16).jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "5.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.757Z",
      updatedAt: "2023-06-11T18:57:41.757Z",
    },
    {
      _id: "64861925ff3c0c6fd30c745b",
      name: "Cushion Halo Solitaire Ring",
      price: 2500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663955574image3 (10).jpeg",
      sliderImages: [
        "1663955574image3 (10).jpeg",
        "1663955574image5 (5).jpeg",
        "1663955574image6 (1).jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "3gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.757Z",
      updatedAt: "2023-06-11T18:57:41.757Z",
    },
    {
      _id: "64861925ff3c0c6fd30c745c",
      name: "Three Row Diamond Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1663955668image8.jpeg",
      sliderImages: [
        "1663955668image4 (7).jpeg",
        "1663955668image7 (1).jpeg",
        "1663955668image8.jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "5.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.757Z",
      updatedAt: "2023-06-11T18:57:41.757Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7462",
      name: "Emerald Minimal Ring",
      price: 2000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664002702DSC_1698-01 (1).jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "2.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.757Z",
      updatedAt: "2023-06-11T18:57:41.757Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7463",
      name: "Halo Minimal Ring",
      price: 2000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664003998DSC_1806-01 (1).jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "Rose",
      width: "6mm",
      weight: "2.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.757Z",
      updatedAt: "2023-06-11T18:57:41.757Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7474",
      name: "Cushion Stack Set",
      price: 3850,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664006574DSC_3775-01.jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.762Z",
      updatedAt: "2023-06-11T18:57:41.762Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7478",
      name: "Milla Ring",
      price: 2300,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664008156SAR_2790-01 (1).jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "5mm",
      weight: "2.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.763Z",
      updatedAt: "2023-06-11T18:57:41.763Z",
    },
    {
      _id: "64861925ff3c0c6fd30c7479",
      name: "Emerald Ring",
      price: 2500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664008217SAR_2794-01.jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "2.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.763Z",
      updatedAt: "2023-06-11T18:57:41.763Z",
    },
    {
      _id: "64861925ff3c0c6fd30c747a",
      name: "Dreamer Eternity Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664008291SAR_2769-01 (1).jpeg",
      sliderImages: ["1664008291SAR_2731-01 (1).jpeg"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.763Z",
      updatedAt: "2023-06-11T18:57:41.763Z",
    },
    {
      _id: "64861925ff3c0c6fd30c747b",
      name: "Chevron Baguette Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664008378SAR_2765-01 (1).jpeg",
      sliderImages: [
        "1664008378SAR_2740-01 (1).jpeg",
        "1664008378SAR_2765-01 (1).jpeg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.763Z",
      updatedAt: "2023-06-11T18:57:41.763Z",
    },
    {
      _id: "64861925ff3c0c6fd30c747c",
      name: "Graca Round Eternity Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664008429SAR_2765-01 (1).jpeg",
      sliderImages: ["1664008429SAR_2740-01 (1).jpeg"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.764Z",
      updatedAt: "2023-06-11T18:57:41.764Z",
    },
    {
      _id: "64861925ff3c0c6fd30c747d",
      name: "Joana Eternity Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664008522SAR_2757-01 (2).jpeg",
      sliderImages: ["1664008522SAR_2740-01 (1).jpeg"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.764Z",
      updatedAt: "2023-06-11T18:57:41.764Z",
    },
    {
      _id: "64861925ff3c0c6fd30c747e",
      name: "Two Tone Band",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664008604SAR_2777-01.jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White, Yellow",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.764Z",
      updatedAt: "2023-06-11T18:57:41.764Z",
    },
    {
      _id: "64861925ff3c0c6fd30c747f",
      name: "Double Solitaire Ring",
      price: 2850,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664008737SAR_2772-01 (1).jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "12mm",
      weight: "2.5gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.764Z",
      updatedAt: "2023-06-11T18:57:41.764Z",
    },
    {
      _id: "64861925ff3c0c6fd30c749b",
      name: "Eternity Band Stack",
      price: 6000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664088748SAR_0863-01 (1).jpeg",
      sliderImages: [],
      stock: 1,
      tags: ["Stack Rings"],
      isPublished: true,
      colour: "White",
      width: "18mm",
      weight: "5.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.768Z",
      updatedAt: "2023-06-11T18:57:41.768Z",
    },
    {
      _id: "64861925ff3c0c6fd30c749c",
      name: "Cushion Classic Solitaire",
      price: 2300,
      category: "6475d4e5cf3fa045768be79a",
      image: "1664089007SAR_0847-01 (1).jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "2.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.768Z",
      updatedAt: "2023-06-11T18:57:41.768Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74a8",
      name: "Emerald Oval Band",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1667919475SVB_6201.jpeg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.770Z",
      updatedAt: "2023-06-11T18:57:41.770Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74a9",
      name: "Emerald Stack Ring",
      price: 3000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1667919525SVB_6219.jpeg",
      sliderImages: ["1667919525SVB_6229.jpg", "1667919525SVB_6234.jpg"],
      stock: 1,
      tags: ["stack rings"],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.770Z",
      updatedAt: "2023-06-11T18:57:41.770Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74aa",
      name: "Trion Ring",
      price: 2000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1667919588SVB_6289.jpg",
      sliderImages: [
        "1667919588SVB_6280.jpeg",
        "1667919588SVB_6289.jpg",
        "1667919588SVB_6291.jpg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "2gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.770Z",
      updatedAt: "2023-06-11T18:57:41.770Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74ab",
      name: "Trion Ring",
      price: 2000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1667919650SVB_6294.jpeg",
      sliderImages: ["1667919650SVB_6291.jpg", "1667919650SVB_6294.jpeg"],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "2gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.770Z",
      updatedAt: "2023-06-11T18:57:41.770Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74ac",
      name: "Baguette Stack Set",
      price: 4950,
      category: "6475d4e5cf3fa045768be79a",
      image: "1667919715SVB_6273.jpeg",
      sliderImages: ["1667919715SVB_6264.jpg"],
      stock: 1,
      tags: ["stack rings"],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.770Z",
      updatedAt: "2023-06-11T18:57:41.770Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74ad",
      name: "Eternity Audrey Band",
      price: 3500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1667919971SVB_6245.jpeg",
      sliderImages: [
        "1667919971SVB_6250.jpg",
        "1667919971SVB_6251.jpg",
        "1667919971SVB_6258 - Copy.jpg",
      ],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "4.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.770Z",
      updatedAt: "2023-06-11T18:57:41.770Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74ae",
      name: "Emerald Band",
      price: 4500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1667920042SVB_6277.jpg",
      sliderImages: [],
      stock: 1,
      tags: [],
      isPublished: true,
      colour: "White",
      width: "9.10mm",
      weight: "5.42gm",
      __v: 0,
      createdAt: "2023-06-11T18:57:41.770Z",
      updatedAt: "2023-06-11T18:57:41.770Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74b4",
      name: "Emerald Oval Solitaire Ring - Christmas Edit",
      price: 2000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671092938SVB_1703-01.jpeg",
      sliderImages: [],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.770Z",
      updatedAt: "2023-06-11T18:57:41.770Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74b5",
      name: "Crown Ring - Christmas Edit",
      price: 2300,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671093112SVB_1668-01.jpeg",
      sliderImages: [],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74b6",
      name: "Baguette Ring - Christmas Edit",
      price: 1599,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671093168SVB_1822-01.jpeg",
      sliderImages: [],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74b7",
      name: "Flora Ring - Christmas Edit",
      price: 1950,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671093407tinywow_image15_9772016.jpg",
      sliderImages: ["1671093407tinywow_image11_9771972.jpg"],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74b8",
      name: "Flora Set - Christmas Edit",
      price: 4950,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671093667tinywow_image9 (1)_9772224.png",
      sliderImages: [],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74b9",
      name: "Pear Ruby Ring - Christmas Edit",
      price: 1500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671093781tinywow_image12_9772348.jpg",
      sliderImages: [],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74ba",
      name: "Pear Solitaire - Christmas Edit",
      price: 1900,
      category: "6475d4e5cf3fa045768be79a",
      image: "SVB_1634-01.jpeg",
      sliderImages: [
        "SVB_1631-01.1671093930.jpeg",
        "SVB_1634-01.1671093930.jpeg",
      ],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74bd",
      name: "Drop Pear Ring - Christmas Edit",
      price: 2500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671094101SVB_1660-01.jpeg",
      sliderImages: ["1671094101SVB_1658-01.jpeg"],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74bf",
      name: "Baguette Ring - Christmas Edit",
      price: 1950,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671094245SVB_1698-01.jpeg",
      sliderImages: ["1671094245SVB_1688-01.jpeg"],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74c0",
      name: "Minimal Heart Ring - Christmas Edit",
      price: 1850,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671094341SVB_1887-01.jpeg",
      sliderImages: [
        "1671094341SVB_1914-01.jpeg",
        "1671094341SVB_1922-01.jpeg",
      ],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74c1",
      name: "Oval Solitaire Ring - Christmas Edit",
      price: 2000,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671094641SVB_1903-01.jpeg",
      sliderImages: ["1671094641tinywow_image0 (59)_9773065.jpg"],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.771Z",
      updatedAt: "2023-06-11T18:57:41.771Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74c2",
      name: "Scattered Minimal Diamond Ring - Christmas Edit",
      price: 1450,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671094789SVB_1841-01.jpeg",
      sliderImages: ["1671094789image1 (23).jpeg"],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.772Z",
      updatedAt: "2023-06-11T18:57:41.772Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74c3",
      name: "Ruby Solitaire Ring - Christmas edit",
      price: 1950,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671094902SVB_1815-01.jpeg",
      sliderImages: [],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.772Z",
      updatedAt: "2023-06-11T18:57:41.772Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74c4",
      name: "Minimal Flora Ring - Christmas Edit",
      price: 1550,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671094983SVB_1806-01.jpeg",
      sliderImages: [],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.772Z",
      updatedAt: "2023-06-11T18:57:41.772Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74c6",
      name: "Mini Heart Stack - Christmas Edit",
      price: 2200,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671095231image14.jpeg",
      sliderImages: ["1671095231image13.jpeg"],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.772Z",
      updatedAt: "2023-06-11T18:57:41.772Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74c7",
      name: "Mini Baguette Stack - Christmas Edit",
      price: 2050,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671095475image6 (1).jpeg",
      sliderImages: [],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.772Z",
      updatedAt: "2023-06-11T18:57:41.772Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74c8",
      name: "Mini Pear Ring - Christmas Edit",
      price: 1350,
      category: "6475d4e5cf3fa045768be79a",
      image: "1671095676image7 (2).jpeg",
      sliderImages: [],
      stock: 1,
      tags: ["Christmas"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.772Z",
      updatedAt: "2023-06-11T18:57:41.772Z",
    },
    {
      _id: "64861925ff3c0c6fd30c74c9",
      name: "Love Charm Ring",
      price: 2500,
      category: "6475d4e5cf3fa045768be79a",
      image: "1675240790tinywow_SVB_7051_12826510.webp",
      sliderImages: ["1675240790tinywow_SVB_7048_12826470.webp"],
      stock: 1,
      tags: ["Valentines Collection"],
      isPublished: true,
      __v: 0,
      createdAt: "2023-06-11T18:57:41.772Z",
      updatedAt: "2023-06-11T18:57:41.772Z",
    },
  ],
};

export default individualCat;
