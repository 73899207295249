import { Box, styled } from "@mui/material";

export const PLPWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "75%",
  p: {
    padding: "1% 0",
    fontWeight: 700,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const ProductsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "space-between",
  gap: 2,
  width: "100%",
}));

export const CharmsWrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  width: "100%",
  justifyItems: "center",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(5, 1fr)",
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

export const ProductBox = styled(Box)(({ theme }) => ({
  margin: "10px 0",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  img: {
    height: "100px",
    width: "100px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  p: {
    fontSize: "14px",
    marginTop: "5px",
  },
  "&:hover": {
    backgroundColor: theme.palette.transparent,
    color: theme.palette.common.black,
    boxShadow: `0px 0px 5px ${theme.palette.primary.main}`,
  },
}));
