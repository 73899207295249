import { CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CharmsWrapper, ProductBox, ProductsWrapper } from "../styles";

const CharmsList = ({ charmsList, selectedCharms, setSelectedCharms }) => {
  const [symbolsList, setSymbolsList] = useState([]);
  const [zodiacList, setZodiacList] = useState([]);
  const [metalAlphabets, setMetalAlphabets] = useState([]);
  const [diamondAlphabets, setDiamondAlphabets] = useState([]);
  const [metalNumbers, setMetalNumbers] = useState([]);
  const [diamondNumbers, setDiamondNumbers] = useState([]);

  useEffect(() => {
    setSymbolsList(charmsList.filter((c) => c.type === "symbol"));
    setZodiacList(charmsList.filter((c) => c.type === "zodiac"));
    setMetalAlphabets(charmsList.filter((c) => c.type === "metalAlphabets"));
    setDiamondAlphabets(
      charmsList.filter((c) => c.type === "diamondAlphabets")
    );
    setMetalNumbers(charmsList.filter((c) => c.type === "metalNumbers"));
    setDiamondNumbers(charmsList.filter((c) => c.type === "diamondNumbers"));
  }, [charmsList]);

  const handleClick = (charm) => {
    const isCharmSelected = selectedCharms.some(
      (selectedCharm) => selectedCharm.id === charm.id
    );
    if (isCharmSelected) {
      setSelectedCharms(
        selectedCharms.filter((selectedCharm) => selectedCharm.id !== charm.id)
      );
    } else {
      setSelectedCharms([...selectedCharms, charm]);
    }
  };

  const renderCharmList = (title, charmList, showName) => (
    <ProductsWrapper>
      <Typography
        fontFamily={"Fraunces"}
        fontSize={"18px"}
        textAlign={"left"}
        marginY={"10px"}>
        {title}
      </Typography>
      <CharmsWrapper>
        {charmList.map((charm) => (
          <ProductBox
            key={charm.id}
            className="product-box"
            onClick={() => handleClick(charm)}>
            <img
              src={charm.image}
              alt={charm.name}
              style={{
                filter: selectedCharms.includes(charm)
                  ? "invert(20%) sepia(25%) saturate(405%) hue-rotate(331deg) brightness(90%) contrast(90%)"
                  : null,
              }}
            />
            {showName && <Typography variant="body1">{charm.name}</Typography>}
          </ProductBox>
        ))}
      </CharmsWrapper>
    </ProductsWrapper>
  );

  return (
    <>
      {selectedCharms.length > 0 && (
        <>
          <Typography
            fontFamily={"Fraunces"}
            fontSize={"20px"}
            textAlign={"left"}
            marginY={"10px"}
            fontWeight={"600"}>
            Your Charms
          </Typography>
          <ProductsWrapper>
            <CharmsWrapper>
              {selectedCharms.map((charm) => (
                <ProductBox
                  key={charm.id}
                  className="product-box"
                  onClick={() => handleClick(charm)}>
                  <img src={charm.image} alt={charm.name} />
                  {/* <Box>
                  <Typography variant="body1">{charm.name}</Typography>
                </Box> */}
                </ProductBox>
              ))}
            </CharmsWrapper>
          </ProductsWrapper>
        </>
      )}
      {charmsList?.length > 0 ? (
        <>
          {renderCharmList("Symbol Charms", symbolsList, true)}
          {renderCharmList("Zodiac Charms", zodiacList, true)}
          {renderCharmList("Metal Alphabet Charms", metalAlphabets)}
          {renderCharmList("Diamond Alphabet Charms", diamondAlphabets)}
          {renderCharmList("Metal Number Charms", metalNumbers)}
          {renderCharmList("Diamond Number Charms", diamondNumbers)}
        </>
      ) : (
        <CircularProgress disableShrink sx={{ margin: "auto" }} />
      )}
    </>
  );
};

export default CharmsList;
