import axios from "axios";
import {
  CHARMS_FAILURE,
  CHARMS_REQUEST,
  GET_CHARMS_SUCCESS,
} from "../actionTypes";

const getAllCharms = (params) => (dispatch) => {
  dispatch({ type: CHARMS_REQUEST });
  axios
    .get(`${process.env.REACT_APP_SERVER}/charms`)
    .then((res) => {
      dispatch({ type: GET_CHARMS_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: CHARMS_FAILURE });
    });
};

export default getAllCharms;
