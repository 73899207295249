/* eslint-disable import/no-extraneous-dependencies */
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { MuiFileInput } from "mui-file-input";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  deleteFromS3,
  deleteMultipleFromS3,
  uploadMultipleToS3,
  uploadToS3,
} from "../../../config/s3Upload";
import { getCategory } from "../../../redux/categoryReducer/action";
import { getCollection } from "../../../redux/collectionReducer/action";
import {
  deleteProduct,
  editProduct,
  getAllProducts,
} from "../../../redux/productReducer/action";
import { FileInputBox } from "../../admin-header/styles";
import AdminSidebar from "../../admin-sidebar/components/AdminSidebar";
import AdminTable from "../../admin-table/components/AdminTable";
import SendNotifications from "../../notifications/components/SendNotifications";

const AdminProducts = () => {
  const dispatch = useDispatch();
  const { products, isLoading: productIsLoading } = useSelector(
    (store) => store.productReducer,
    shallowEqual
  );

  const { categories, isLoading: categoryIsLoading } = useSelector(
    (store) => store.categoryReducer,
    shallowEqual
  );

  const { collections, isLoading: collectionIsLoading } = useSelector(
    (store) => store.collectionReducer,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getCollection());
    dispatch(
      getAllProducts({ page: 1, limit: !productIsLoading && products?.total })
    );
  }, []);

  const [rows, setRows] = useState([]);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [productDetails, setProductDetails] = useState({
    id: "",
    name: "",
    price: "",
    other: "",
    categoryNumber: null,
    collectionNumber: null,
    tags: "",
    image: "",
    sliderImages: "",
    isPublished: true,
    color: "",
    width: "",
    weight: "",
    length: "",
  });
  const [newProduct, setNewProduct] = useState({
    id: "",
    name: "",
    price: "",
    other: "",
    categoryNumber: null,
    collectionNumber: null,
    tags: "",
    image: "",
    sliderImages: "",
    isPublished: true,
    color: "",
    width: "",
    weight: "",
    length: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productImage, setProductImage] = useState(null);
  const [sliderImages, setSliderImages] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({
    type: "",
    entity: "",
    action: "",
  });
  const [lengthValue, setlengthValue] = useState();
  const [descValue, setDescValue] = useState("");

  const lengthOptions = [
    {
      label:
        "Customised According To Size (model in the picture is wearing 6 inches chain length)",
      value:
        "Customised According To Size (model in the picture is wearing 6 inches chain length)",
    },
    {
      label:
        "15inches, 16inches, 18inches and 20inches (model in the picture is wearing 16 inches chain length)",
      value:
        "15inches, 16inches, 18inches and 20inches (model in the picture is wearing 16 inches chain length)",
    },
  ];

  const descOptions = [
    {
      label: "Please choose from options",
      value: "",
    },
    {
      label: "*This does not include watch with it",
      value: "*This does not include watch with it",
    },
    {
      label: "Earrings can either be customised in screw, push or flat",
      value: "Earrings can either be customised in screw, push or flat",
    },
  ];

  const newRows = [];
  useEffect(() => {
    const productsArr = products.products;
    if (Array.isArray(productsArr)) {
      productsArr.forEach((product, index) => {
        const categoryName = categories.find(
          (el) => product.categoryNumber === el.categoryNumber
        );
        const collectionName = collections.find(
          (el) => product.collectionNumber === el.collectionNumber
        );
        const category = categoryName?.name || "-";
        const collection = collectionName?.name || "-";
        const data = {
          id: product._id,
          name: product.name,
          category,
          collection,
          update: new Date(product.updatedAt).toLocaleString("en-IN"),
          image: product.image,
        };
        newRows.push(data);
      });
    }
    setRows(newRows);
  }, [products]);

  const createProduct = () => {
    setOpenCreateModal(true);
  };

  const handleProductImageChange = (newFile) => {
    setProductImage(newFile);
    if (openCreateModal) {
      setNewProduct({
        ...newProduct,
        image: newFile.name,
      });
    } else {
      setProductDetails({
        ...productDetails,
        image: newFile.name,
      });
    }
  };

  const handleSliderImageChange = (newFiles) => {
    setSliderImages(newFiles);
    setProductDetails({
      ...productDetails,
      sliderImages:
        typeof newFiles[0] === "string"
          ? newFiles
          : newFiles.map((file) => file.name),
    });
  };

  const handleEditProduct = (product) => {
    const targetProduct = products.products.find(
      (ele) => ele._id === product.row.id
    );
    setDescValue(targetProduct.other);
    setProductDetails({
      id: product.row.id,
      name: product.row.name,
      price: targetProduct.price,
      other: targetProduct.other,
      categoryNumber: product.row.categoryNumber,
      collectionNumber: product.row.collectionNumber || null,
      tags: targetProduct.tags,
      image: product.row.image,
      sliderImages: targetProduct.sliderImages,
      isPublished: true,
      color: targetProduct.color,
      width: targetProduct.width,
      weight: targetProduct.weight,
      length: targetProduct.length,
    });
    setOpenProductModal(true);
  };

  const handleProductSubmit = async (type = "edit") => {
    if (type === "new") {
      try {
        let updatedProductDetails = {
          ...newProduct,
          price: Number(newProduct.price),
        };
        setNewProduct(updatedProductDetails);
        const uploadResult = await uploadToS3(productImage);

        let sliderUpload;
        if (sliderImages !== null) {
          sliderUpload = await uploadMultipleToS3(sliderImages);
        }

        if (uploadResult.result.ok) {
          updatedProductDetails = {
            ...updatedProductDetails,
            image: uploadResult.location,
            sliderImages: sliderUpload?.length ? sliderUpload : [],
          };
          const response = await axios.post(
            `${process.env.REACT_APP_ADMIN_SERVER}/products`,
            updatedProductDetails
          );
        }
        setShowNotification(true);
        setNotification({
          type: "success",
          entity: "product",
          action: "create",
        });
        setNewProduct({
          id: "",
          name: "",
          price: "",
          other: "",
          categoryNumber: null,
          collectionNumber: null,
          tags: "",
          image: "",
          sliderImages: "",
          isPublished: true,
          color: "",
          width: "",
          weight: "",
          length: "",
        });
        setOpenCreateModal(false);
      } catch (error) {
        setShowNotification(true);
        setNotification({
          entity: "product",
          action: "create",
        });
      }
    } else {
      try {
        let updatedProductDetails = {
          ...productDetails,
          price: Number(productDetails.price),
        };
        setProductDetails(updatedProductDetails);
        let uploadResult = "";
        if (productImage !== null) {
          uploadResult = await uploadToS3(productImage);
          if (uploadResult.result.ok) {
            updatedProductDetails = {
              ...updatedProductDetails,
              image: uploadResult.location,
            };
          }
        }
        dispatch(editProduct(productDetails.id, updatedProductDetails));
        setProductDetails({
          id: "",
          name: "",
          price: "",
          other: "",
          categoryNumber: null,
          collectionNumber: null,
          tags: "",
          image: "",
          sliderImages: "",
          isPublished: true,
          color: "",
          width: "",
          weight: "",
          length: "",
        });
        setOpenProductModal(false);
        setShowNotification(true);
        setNotification({
          type: "success",
          entity: "product",
          action: "update",
        });
      } catch (error) {
        setShowNotification(true);
        setNotification({
          entity: "product",
          action: "update",
        });
      }
    }
  };

  const handleDeleteProduct = (product) => {
    const productData = products.products.find(
      (ele) => ele._id === product.row.id
    );
    setProductDetails({
      id: product.row.id,
      name: productData.name,
      price: productData.price,
      other: productData.other,
      categoryNumber: productData.categoryNumber,
      collectionNumber: productData.collectionNumber || null,
      tags: productData.tags,
      image: productData.image,
      sliderImages: productData.sliderImages,
      isPublished: true,
      color: productData.color,
      width: productData.width,
      weight: productData.weight,
      length: productData.length,
    });
    setIsDeleteModalOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "Product ID",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Product Name",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "category",
      headerName: "Product Category",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    { field: "collection", headerName: "Product Collection", width: 200 },
    {
      field: "image",
      headerName: "Image",
      width: 130,
      headerAlign: "center",
      align: "center",
    },
    { field: "update", headerName: "Last Modified On", width: 130 },
    {
      field: "edit",
      headerName: "Edit Product",
      width: 100,
      renderCell: (params) => (
        <Box
          onClick={() => handleEditProduct(params)}
          sx={{
            cursor: "pointer",
          }}>
          <EditIcon color="primary" />
        </Box>
      ),
      align: "center",
    },
    {
      field: "delete",
      headerName: "Delete Product",
      width: 150,
      renderCell: (params) => (
        <Box
          onClick={() => handleDeleteProduct(params)}
          sx={{
            cursor: "pointer",
          }}>
          <DeleteIcon color="primary" />
        </Box>
      ),
      align: "center",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}>
      <AdminSidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "85%",
          height: "45%",
          justifyContent: "space-evenly",
          textAlign: "center",
        }}>
        {showNotification && <SendNotifications {...notification} />}
        <Box>
          <Typography variant="h4" color={"primary"}>
            Products
          </Typography>
          <Typography variant="h5">Find all your products here.</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}>
          <Button onClick={createProduct} variant="contained">
            Create New Product
          </Button>
          <Modal
            open={openCreateModal}
            onClose={() => {
              setOpenCreateModal(false);
            }}>
            <Box
              component={"form"}
              encType="multipart/form-data"
              onSubmit={() => {
                handleProductSubmit("new");
              }}
              noValidate
              autoComplete="off"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                maxHeight: "80vh",
                overflowY: "auto",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}>
                <Typography variant="h3" fontSize={"25px"}>
                  <Typography
                    display="inline"
                    color="primary"
                    variant="h3"
                    fontSize={"25px"}
                    fontWeight={700}>
                    Create{" "}
                  </Typography>
                  New Product
                </Typography>
                <IconButton onClick={() => setOpenCreateModal(false)}>
                  <CloseIcon fontSize="large" color="primary" />
                </IconButton>
              </Box>
              <Typography color={"primary"} fontWeight={"700"}>
                Name
              </Typography>
              <TextField
                label="Name"
                value={newProduct.name}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, name: e.target.value })
                }
                fullWidth
                sx={{ margin: "8px 0" }}
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Category
              </Typography>
              <Select
                onChange={(e) =>
                  setNewProduct({
                    ...newProduct,
                    categoryNumber: e.target.value,
                  })
                }
                fullWidth
                sx={{ margin: "8px 0" }}>
                <MenuItem value="">Select a category</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category.categoryNumber}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography color={"primary"} fontWeight={"700"}>
                Collection
              </Typography>
              <Select
                onChange={(e) =>
                  setNewProduct({
                    ...newProduct,
                    collectionNumber: e.target.value,
                  })
                }
                fullWidth>
                <MenuItem value="">Select a collection</MenuItem>
                {collections.map((collection) => (
                  <MenuItem
                    key={collection._id}
                    value={collection.collectionNumber}>
                    {collection.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography color={"primary"} fontWeight={"700"}>
                Price
              </Typography>
              <TextField
                label="Price"
                placeholder="Enter product price"
                value={newProduct.price}
                onChange={(e) =>
                  setNewProduct({
                    ...newProduct,
                    price: e.target.value,
                  })
                }
                fullWidth
                sx={{ margin: "8px 0" }}
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Color
              </Typography>
              <TextField
                label="Color"
                value={newProduct.color}
                onChange={(e) =>
                  setNewProduct({
                    ...newProduct,
                    color: e.target.value,
                  })
                }
                fullWidth
                sx={{ margin: "8px 0" }}
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Width
              </Typography>
              <TextField
                label="Width"
                value={newProduct.width}
                onChange={(e) =>
                  setNewProduct({
                    ...newProduct,
                    width: e.target.value,
                  })
                }
                fullWidth
                sx={{ margin: "8px 0" }}
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Weight
              </Typography>
              <TextField
                label="Weight"
                value={newProduct.weight}
                onChange={(e) =>
                  setNewProduct({
                    ...newProduct,
                    weight: e.target.value,
                  })
                }
                fullWidth
                sx={{ margin: "8px 0" }}
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Length
              </Typography>
              <Autocomplete
                freeSolo
                options={lengthOptions}
                value={lengthValue}
                onChange={(event, newValue) => {
                  setlengthValue(newValue[0]);
                }}
                inputValue={newProduct.length}
                onInputChange={(event, newValue) =>
                  setNewProduct({ ...newProduct, length: newValue[0] })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Length"
                    fullWidth
                    sx={{ margin: "8px 0" }}
                  />
                )}
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Other
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newProduct.other}
                label="Other"
                autoWidth
                defaultValue=""
                onChange={(e) =>
                  setNewProduct({
                    ...newProduct,
                    other: e.target.value,
                  })
                }>
                {descOptions.map((option, index) => (
                  <MenuItem value={option.value} key={index}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <Typography color={"primary"} fontWeight={"700"}>
                Tags
              </Typography>
              <TextField
                label="Tags"
                value={newProduct.tags}
                onChange={(e) =>
                  setNewProduct({ ...newProduct, tags: e.target.value })
                }
                fullWidth
                sx={{ margin: "8px 0" }}
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Image
              </Typography>
              <FileInputBox
                value={setProductImage}
                className="file-input"
                onChange={handleProductImageChange}
                name="image"
                type="file"
              />
              <Typography color={"primary"} fontWeight={"700"}>
                Slider Images
              </Typography>
              <MuiFileInput
                placeholder="Add 1 or more images"
                value={sliderImages}
                onChange={handleSliderImageChange}
                name="sliderImage"
                type="file"
                multiple
              />
              <Button
                onClick={() => {
                  handleProductSubmit("new");
                }}
                fullWidth
                variant="contained">
                Submit
              </Button>
            </Box>
          </Modal>
        </Box>
        {rows.length > 0 && (
          <>
            <AdminTable columns={columns} rows={rows} />
            <Modal
              open={openProductModal}
              onClose={() => setOpenProductModal(false)}>
              <Box
                component={"form"}
                encType="multipart/form-data"
                onSubmit={() => handleProductSubmit()}
                noValidate
                autoComplete="off"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 600,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <Typography variant="h3" fontSize={"25px"}>
                    <Typography
                      display="inline"
                      color="primary"
                      variant="h3"
                      fontSize={"25px"}
                      fontWeight={700}>
                      Edit{" "}
                    </Typography>
                    Product
                  </Typography>
                  <IconButton onClick={() => setOpenProductModal(false)}>
                    <CloseIcon fontSize="large" color="primary" />
                  </IconButton>
                </Box>
                <Typography color={"primary"}>Name</Typography>
                <TextField
                  label="Name"
                  value={productDetails.name}
                  onChange={(e) =>
                    setProductDetails({
                      ...productDetails,
                      name: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ margin: "8px 0" }}
                  required
                />
                <Typography color={"primary"}>Category</Typography>
                <Select
                  onChange={(e) =>
                    setProductDetails({
                      ...productDetails,
                      categoryNumber: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ margin: "8px 0" }}
                  required>
                  <MenuItem value={productDetails.categoryNumber}>
                    Select a category
                  </MenuItem>
                  {categories.map((category) => (
                    <MenuItem
                      key={category._id}
                      value={category.categoryNumber}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography color={"primary"}>Collection</Typography>
                <Select
                  onChange={(e) =>
                    setProductDetails({
                      ...productDetails,
                      collectionNumber: e.target.value,
                    })
                  }
                  fullWidth>
                  <MenuItem value={productDetails.collectionNumber}>
                    Select a collection
                  </MenuItem>
                  {collections.map((collection) => (
                    <MenuItem
                      key={collection._id}
                      value={collection.collectionNumber}>
                      {collection.name}
                    </MenuItem>
                  ))}
                </Select>
                <Typography color={"primary"}>Price</Typography>
                <TextField
                  label="Price"
                  placeholder="Enter product price"
                  value={productDetails.price}
                  onChange={(e) =>
                    setProductDetails({
                      ...productDetails,
                      price: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ margin: "8px 0" }}
                  required
                />
                <Typography color={"primary"}>Color</Typography>
                <TextField
                  label="Color"
                  value={productDetails.color}
                  onChange={(e) =>
                    setProductDetails({
                      ...productDetails,
                      color: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ margin: "8px 0" }}
                />
                <Typography color={"primary"}>Width</Typography>
                <TextField
                  label="Width"
                  value={productDetails.width}
                  onChange={(e) =>
                    setProductDetails({
                      ...productDetails,
                      width: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ margin: "8px 0" }}
                />
                <Typography color={"primary"}>Weight</Typography>
                <TextField
                  label="Weight"
                  value={productDetails.weight}
                  onChange={(e) =>
                    setProductDetails({
                      ...productDetails,
                      weight: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ margin: "8px 0" }}
                />
                <Typography color={"primary"}>Length</Typography>
                <Autocomplete
                  freeSolo
                  options={lengthOptions}
                  value={lengthValue}
                  onChange={(event, newValue) => {
                    setlengthValue(newValue[0]);
                  }}
                  inputValue={productDetails.length}
                  onInputChange={(event, newValue) =>
                    setProductDetails({
                      ...productDetails,
                      length: newValue[0],
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Length"
                      fullWidth
                      sx={{ margin: "8px 0" }}
                    />
                  )}
                />
                <Typography color={"primary"}>Other</Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  autoWidth
                  defaultValue=""
                  value={productDetails.other}
                  label="Other"
                  onChange={(e) =>
                    setProductDetails({
                      ...productDetails,
                      other: e.target.value,
                    })
                  }>
                  {descOptions.map((option, index) => (
                    <MenuItem value={option.value} key={index}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <Typography color={"primary"}>Tags</Typography>
                <TextField
                  label="Tags"
                  value={productDetails.tags}
                  onChange={(e) =>
                    setProductDetails({
                      ...productDetails,
                      tags: e.target.value,
                    })
                  }
                  fullWidth
                  sx={{ margin: "8px 0" }}
                />
                <Typography color={"primary"}>Image</Typography>
                <Tooltip
                  title="This is the current image. If you wish to change it, upload a new one below"
                  arrow>
                  <Typography sx={{ cursor: "pointer" }}>
                    {productDetails.image}
                  </Typography>
                </Tooltip>
                <FileInputBox
                  value={productImage}
                  className="file-input"
                  onChange={handleProductImageChange}
                  name="image"
                  type="file"
                  required
                />
                <Typography color={"primary"}>Slider Images</Typography>
                <Tooltip
                  title="These are the current slider image/(s). If you wish to change them, upload new ones below"
                  arrow>
                  <Typography sx={{ cursor: "pointer" }}>
                    {productDetails.sliderImages.toString()}
                  </Typography>
                </Tooltip>
                <MuiFileInput
                  placeholder="Add 1 or more images"
                  value={sliderImages}
                  onChange={handleSliderImageChange}
                  name="sliderImage"
                  type="file"
                  multiple
                />
                <Button
                  onClick={() => handleProductSubmit()}
                  fullWidth
                  variant="contained">
                  Submit
                </Button>
              </Box>
            </Modal>
            <Modal
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "35%",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                  }}>
                  <Typography variant="h3">Are you sure?</Typography>
                  <IconButton
                    onClick={() => setIsDeleteModalOpen(false)}
                    sx={{
                      position: "absolute",
                      right: "1px",
                    }}>
                    <CloseIcon />
                  </IconButton>
                  <Typography>
                    Are you sure you want to delete
                    <Typography display={"inline"} fontWeight={"700"}>
                      {" "}
                      {productDetails.name}{" "}
                    </Typography>
                    product?
                  </Typography>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      width: "50%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}>
                    <Button
                      color="success"
                      onClick={async () => {
                        const deleteResult = await deleteFromS3(
                          productDetails.image
                        );
                        let deleteMultiple;
                        if (productDetails.sliderImages !== null) {
                          deleteMultiple = await deleteMultipleFromS3(
                            productDetails.sliderImages
                          );
                        }
                        if (deleteResult) {
                          const deleteRes = dispatch(
                            deleteProduct(productDetails.id)
                          );
                          setIsDeleteModalOpen(false);
                          setShowNotification(true);
                          setNotification({
                            type: "success",
                            entity: "product",
                            action: "delete",
                          });
                        } else {
                          setShowNotification(true);
                          setNotification({
                            entity: "product",
                            action: "delete",
                          });
                        }
                      }}
                      variant="contained">
                      Yes
                    </Button>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        setIsDeleteModalOpen(false);
                      }}>
                      No
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Modal>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdminProducts;
