import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const SendNotifications = ({ type = "error", entity, action }) => {
  const [isOpen, setIsOpen] = useState(true);

  const actionMessages = {
    create: {
      success: `Successfully created new ${entity}`,
      error: `An error occured while creating new ${entity}`,
    },
    update: {
      success: `Successfully updated ${entity}`,
      error: `An error occured while updating ${entity}`,
    },
    delete: {
      success: `Successfully deleted ${entity}`,
      error: `An error occured while deleting ${entity}`,
    },
  };

  if (type === "success") {
    return (
      <Snackbar
        open={isOpen}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        autoHideDuration={6000}
        onClose={() => {
          setIsOpen(false);
        }}>
        <Alert
          onClose={() => {
            setIsOpen(false);
          }}
          severity={type}
          variant="filled"
          sx={{ width: "100%" }}>
          {actionMessages[action].success}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      autoHideDuration={6000}
      onClose={() => {
        setIsOpen(false);
      }}>
      <Alert
        onClose={() => {
          setIsOpen(false);
        }}
        severity={type}
        variant="filled"
        sx={{ width: "100%" }}>
        {actionMessages[action].error}
      </Alert>
    </Snackbar>
  );
};

export default SendNotifications;
