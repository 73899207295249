import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import bestseller from "../../../images/image1.jpg";
import topimage from "../../../images/image2.jpg";
import bottomimage from "../../../images/image8.jpg";
import SectionContainer from "../../section-container/components/SectionContainer";
import HoverImage from "../../hover-image/components/HoverImage";
import HeadingBox from "../../heading-box/components/HeadingBox";
import { CollectionWrapper, ImagesBox } from "../styles";

const HomeBracelet = () => (
  <CollectionWrapper>
    <SectionContainer className={"section-container"}>
      <HeadingBox title={"Create your Own"} />
      <ImagesBox>
        <Link to={`/charms`} component={RouterLink}>
          <HoverImage
            url="https://drijewels-image.s3.ap-south-1.amazonaws.com/Charms_Plate.jpg"
            height={"500px"}
            width={"500px"}
            title={"Bracelet"}
            bestSeller="true"
          />
        </Link>
      </ImagesBox>
    </SectionContainer>
  </CollectionWrapper>
);

export default HomeBracelet;
