import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import AdminSidebar from "../components/admin-sidebar/components/AdminSidebar";
import AdminHeader from "../components/admin-header/components/AdminHeader";
import AdminContent from "../components/admin-content/components/AdminContent";
import { getCategory } from "../redux/categoryReducer/action";
import { getCollection } from "../redux/collectionReducer/action";
import { getAllProducts } from "../redux/productReducer/action";

const AdminHome = () => {
  const dispatch = useDispatch();
  const { products, isLoading: productIsLoading } = useSelector(
    (store) => store.productReducer,
    shallowEqual
  );

  const { categories, isLoading: categoryIsLoading } = useSelector(
    (store) => store.categoryReducer,
    shallowEqual
  );

  const { collections, isLoading: collectionIsLoading } = useSelector(
    (store) => store.collectionReducer,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getCollection());
    dispatch(getAllProducts({ page: 1 }));
  }, []);

  const isUpdatedTwoDaysAgo = (entity) => {
    const updatedAtDate = new Date(entity.updatedAt);
    const timeDifference = new Date() - updatedAtDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    return daysDifference <= 2;
  };

  const [modifiedArr, setModifiedArr] = useState([]);

  useEffect(() => {
    if (productIsLoading) {
      setModifiedArr([
        categories?.filter(isUpdatedTwoDaysAgo),
        collections?.filter(isUpdatedTwoDaysAgo),
      ]);
    } else {
      setModifiedArr([
        categories?.filter(isUpdatedTwoDaysAgo),
        collections?.filter(isUpdatedTwoDaysAgo),
        products?.products?.filter(isUpdatedTwoDaysAgo),
      ]);
    }
  }, [productIsLoading]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
      }}>
      <AdminSidebar />
      <Box width={"85%"}>
        <AdminHeader categories={categories} collections={collections} />
        {!productIsLoading && <AdminContent fullArr={modifiedArr} />}
      </Box>
    </Box>
  );
};

export default AdminHome;
