/* eslint-disable import/no-extraneous-dependencies */
import { uploadFile, deleteFile } from "react-s3";
import { Buffer } from "buffer";

window.Buffer = window.Buffer || Buffer;

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_IAM_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_IAM_ACCESS_KEY_SECRET,
};

export const uploadToS3 = async (image) => {
  const uploadResult = await uploadFile(image, config);
  return uploadResult;
};

export const uploadMultipleToS3 = async (images) => {
  const promiseArr = images.map(async (image) => uploadFile(image, config));
  const uploadResult = (await Promise.all(promiseArr)).map(
    (promise) => promise?.location
  );
  return uploadResult;
};

export const deleteFromS3 = async (image) => {
  const imageName = image.split("/")[3];
  const deleteResult = await deleteFile(imageName, config);
  return deleteResult;
};

export const deleteMultipleFromS3 = async (images) => {
  const promiseArr = images.map(async (image) =>
    deleteFile(image.split("/")[3], config)
  );
  const deleteResult = (await Promise.all(promiseArr)).map(
    (promise) => promise?.fileName
  );
  return deleteResult;
};
