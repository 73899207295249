/* eslint-disable default-param-last */
/* eslint-disable import/prefer-default-export */

import {
  CHARMS_FAILURE,
  CHARMS_REQUEST,
  GET_CHARMS_SUCCESS,
} from "../actionTypes";

const initialState = {
  isLoading: false,
  isError: false,
  charms: [],
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHARMS_REQUEST:
      return { ...state, isLoading: true };
    case CHARMS_FAILURE:
      return { ...state, isLoading: false, isError: true };
    case GET_CHARMS_SUCCESS:
      return { ...state, isLoading: false, charms: payload };
    default:
      return state;
  }
};
