import {
  Box,
  OutlinedInput,
  InputAdornment,
  Typography,
  InputLabel,
  IconButton,
  Button,
  FormControl,
  Alert,
} from "@mui/material";
import { useContext, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { Form, useNavigate } from "react-router-dom";
import SectionContainer from "../components/section-container/components/SectionContainer";
import { AuthContext } from "../context/AuthContextProvider";

const Login = () => {
  const [passwordIcon, setPasswordIcon] = useState(VisibilityOffIcon);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [alert, setAlert] = useState("");
  const { isAuth, loginUser, loading, err } = useContext(AuthContext);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClick = () => {
    setPasswordIcon(VisibilityIcon);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!adminEmail.includes("drijewels")) {
      setAlert("Email should end with @drijewels.com");
    } else {
      const admin = {
        username: adminEmail,
        password: adminPassword,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/login`,
        admin
      );
      if (response.status === 200) {
        localStorage.setItem("isAuth", true);
        loginUser();
        navigate("/admin");
      } else {
        localStorage.setItem("isAuth", false);
        setAlert("Check your email or password again");
      }
      setAdminEmail("");
      setAdminPassword("");
    }
  };

  const handlePasswordKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <SectionContainer
      sx={{
        padding: 0,
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Box
        backgroundColor={"common.white"}
        height={"50%"}
        padding={"2% 0"}
        width={"70%"}
        borderRadius={"10px"}>
        <Typography variant="h3">Login</Typography>
        <Typography marginBottom={"10px"}>
          Login to access admin dashboard
        </Typography>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "75%",
            margin: "0 auto",
            ".MuiInputBase-root": {
              marginBottom: "10px",
            },
            label: {
              color: "#885422",
            },
          }}>
          {alert.length > 0 ? (
            <Alert severity="error" sx={{ marginBottom: "10px" }}>
              {alert}
            </Alert>
          ) : (
            <></>
          )}
          <FormControl>
            <InputLabel htmlFor="authorized-email">Authorized Email</InputLabel>
            <OutlinedInput
              id="authorized-email"
              onChange={(e) => {
                setAdminEmail(e.target.value);
              }}
              label={"Authorized Email"}
              required
            />
          </FormControl>
          <FormControl>
            <InputLabel htmlFor="authorized-password">
              Authorized Password
            </InputLabel>
            <OutlinedInput
              id="authorized-password"
              label={"Authorized Password"}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
              required
              onChange={(e) => {
                setAdminPassword(e.target.value);
              }}
              onKeyUp={handlePasswordKeyUp}
            />
          </FormControl>

          <Button variant="contained" type="submit" onClick={handleSubmit}>
            Login
          </Button>
        </FormControl>
      </Box>
    </SectionContainer>
  );
};

export default Login;
